import Router from "vue-router";
import { createRouter } from "vue2-helpers/vue-router";
import Analytics from "./Analytics";
import Billing from "./Billing";
import Dashboard from "./Dashboard";
import Flow from "./Flow";
import Messages from "./Messages";
import Numbers from "./Numbers";
import Settings from "./Settings";
import ServiceAccounts from "./ServiceAccounts";
import SipDomains from "./SipDomains";
import Auth from "./Auth";
import Calls from "./Calls";
import Campaigns from "./Campaigns";
import Contacts from "./Contacts";
import ContactLists from "./ContactLists";
import Clis from "./Clis";
import RegulatoryListings from "./RegulatoryListings";
import PortInRequests from "./PortInRequests";
import Tenants from "./Tenants";
import Marketplace from "./Marketplace";
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default createRouter({
  // @ts-ignore
  routes: [
    ...Analytics,
    ...Billing,
    ...Dashboard,
    ...Flow,
    ...Messages,
    ...Numbers,
    ...Settings,
    ...SipDomains,
    ...Calls,
    ...Auth,
    ...Campaigns,
    ...Contacts,
    ...ContactLists,
    ...Clis,
    ...RegulatoryListings,
    ...ServiceAccounts,
    ...Tenants,
    ...PortInRequests,
    ...Marketplace,
  ],
});
