<template>
  <v-dialog
    max-width="500"
    v-model="open"
    @click:outside="handleCloseModal()"
  >
    <div class="en-modal">
      <div class="en-modal-header">
        <h4>
          {{ title }}
        </h4>
        <v-icon v-on:click="handleCloseModal()">mdi-close</v-icon>
      </div>

      <div class="en-modal-body">
        {{ description }}
        <slot></slot>
      </div>
      <div class="en-modal-actions">
        <v-spacer></v-spacer>
        <en-button
          class="mr-main"
          @click="handleCloseModal()"
        >
          Cancel
        </en-button>
        <en-button
          :primary="true"
          @click="handleConfirmClick"
        >
          Confirm
        </en-button>
      </div>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
const props = defineProps({
  open: {
    type: Boolean,
    required: false,
  },
  resourceName: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  handleConfirm: {
    type: Function,
    required: false,
  },
  closeModal: {
    type: Function,
    required: false,
  },
});
function handleConfirmClick() {
  // @ts-ignore
  props.handleConfirm(props.resourceName);
}
function handleCloseModal() {
  // @ts-ignore
  props.closeModal();
}
</script>
