import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";
import { NumberingApiUrl } from "@/consts/configuration";
import { CrudService } from "@/api";
import { usePermissions } from "@/composition/usePermissions";
const phoneNumberInstancesApi = new CrudService(NumberingApiUrl);

export default {
  async initializePhoneNumberView({ commit, state, dispatch }, to) {
    try {
      const phoneNumberInstanceRes = await dispatch("phoneNumberInstances/getResource", to, {
        root: true,
      });

      const regulatoryListingRes = await dispatch(
        "regulatoryListings/listAll",
        {
          projectId: to.params.projectId,
          regionCode: phoneNumberInstanceRes.phoneNumber.countryCode,
          numberType: phoneNumberInstanceRes.phoneNumber.numberType,
        },
        { root: true }
      );

      const allAndInvalidRes = await dispatch(
        "regulatoryListings/listAllAndInvalid",
        {
          projectId: to.params.projectId,
        },
        { root: true }
      );
      return {
        regulatoryListings: regulatoryListingRes,
        phoneNumberInstance: phoneNumberInstanceRes,
        allAndInvalidRes,
      };
    } catch (err) {
      throw err;
    }
  },
  async initializeListView({ commit, state, dispatch, getters, rootGetters }, to) {
    const hasFlowFlowsListPermission = rootGetters["user/checkPermissions"]("flow.flows.list");
    console.log(hasFlowFlowsListPermission);
    try {
      if (hasFlowFlowsListPermission) {
        await dispatch(
          "flows/handleListFlows",
          {
            projectName: to.params.projectId,
            includeDeleted: true,
          },
          {
            root: true,
          }
        );
      }
      await dispatch("phoneNumberInstances/listResource", to, {
        root: true,
      });

      return true;
    } catch (err) {
      throw err;
    }
  },
  async getOverview({ commit, state, dispatch }, to) {
    try {
      const phoneNumberInstances = await dispatch("phoneNumberInstances/listResource", to, {
        root: true,
      });

      const allAndInvalidRes = await dispatch(
        "regulatoryListings/listAllAndInvalid",
        {
          projectId: to.params.projectId,
        },
        { root: true }
      );

      const portInRequests = await dispatch("portInRequests/listResource", to, {
        root: true,
      });

      const bundles = await dispatch("bundles/listBundles", to, {
        root: true,
      });

      return {
        phoneNumberInstances,
        allAndInvalidRes,
        portInRequests,
        bundles,
      };
    } catch (err) {
      throw err;
    }
  },
  async listResource({ commit, state }, to) {
    const payload = {
      projectId: to.params.projectId,
      search: to.query.search ? to.query.search : "",
    };

    if (state.numberList.phoneNumberInstances.length > 0 && payload.search) {
      return;
    }

    try {
      let params = "";
      const allSubs = [];
      do {
        const data = await httpClient.get(
          `${NumberingApiUrl}/projects/${payload.projectId}/phoneNumberInstances?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(data.data.phoneNumberInstances);
        params = data.data.nextPageToken;
      } while (params);
      commit("setNumberList", { phoneNumberInstances: allSubs.flat() });
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getResource({ commit, state }, to) {
    const payload = {
      projectId: to.params.projectId,
      phoneNumberId: to.params.phoneNumberId,
    };

    try {
      const data = await httpClient.get(
        `${NumberingApiUrl}/projects/${payload.projectId}/phoneNumberInstances/${payload.phoneNumberId}`
      );
      const numbers = {
        metadata: [],
        ...data.data,
      };

      for (const [key, value] of Object.entries(data.data.labels)) {
        numbers.metadata.push({
          key,
          value,
          edit: false,
        });
      }

      commit("setNumber", numbers);
      return numbers;
    } catch (error) {
      throw error;
    }
  },
  async getPhoneNumbers({ commit, state }, payload) {
    try {
      let params = "";
      const allSubs = [];
      do {
        const data = await httpClient.get(
          `${NumberingApiUrl}/projects/${payload.projectId}/phoneNumberInstances?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(data.data.phoneNumberInstances);
        params = data.data.nextPageToken;
      } while (params);
      commit("setNumberList", { phoneNumberInstances: allSubs.flat() });
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getPhoneNumber({ commit, state }, payload) {
    try {
      const data = await httpClient.get(
        `${NumberingApiUrl}/projects/${payload.projectId}/phoneNumberInstances/${payload.phoneNumberId}`
      );
      const numbers = {
        metadata: [],
        ...data.data,
      };

      for (const [key, value] of Object.entries(data.data.labels)) {
        numbers.metadata.push({
          key,
          value,
          edit: false,
        });
      }
      commit("setNumber", numbers);
      return numbers;
    } catch (error) {
      throw error;
    }
  },
  async createPhoneNumber({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.post(
        `${NumberingApiUrl}/projects/${payload.projectId}/phoneNumberInstances`,
        payload.body
      );
      commit("common/setLoading", false, { root: true });
      return data.data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updatePhoneNumber({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    let regulatoryListingMask = "";
    if (payload?.body?.regulatoryListing) {
      regulatoryListingMask = ",regulatoryListing";
    }
    try {
      const data = await httpClient.patch(
        `${NumberingApiUrl}/projects/${payload.projectId}/phoneNumberInstances/${payload.phoneNumberId}?update_mask=callRecordingTrigger,displayName,incomingMessageHandlerUris,incomingCallHandlerUris,labels` +
          regulatoryListingMask,
        payload.body
      );
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Number Updated",
        },
        { root: true }
      );
      return data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updatePhoneNumberFlow({ commit, state }, payload) {
    try {
      const data = await httpClient.patch(
        `${NumberingApiUrl}/${payload.phoneNumberInstance}?update_mask=incomingCallHandlerUris`,
        payload.body
      );
      return data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deletePhoneNumber({ commit, state }, payload) {
    return phoneNumberInstancesApi.deleteResource("Number", {
      resourceName: `projects/${payload.projectId}/phoneNumberInstances/${payload.phoneNumberId}`,
    });
  },
  async splitPhoneNumber({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.post(
        `${NumberingApiUrl}/${payload.phoneNumberInstance}:splitRange`,
        {
          size: payload.size,
        }
      );
      commit(
        "common/setSuccess",
        {
          data: "Number range successfully split",
        },
        { root: true }
      );
      commit("common/setLoading", false, { root: true });
      return data.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      commit("common/setLoading", false, { root: true });
      throw error;
    }
  },
  async movePhoneNumber({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    let regulatoryListing = {};

    if (payload.regulatoryListing) {
      regulatoryListing = {
        regulatoryListing: payload.regulatoryListing,
      };
    }
    try {
      const data = await httpClient.post(`${NumberingApiUrl}/${payload.phoneNumberInstance}:move`, {
        destinationParent: payload.projectName,
        ...regulatoryListing,
      });
      commit("common/setLoading", false, { root: true });
      return data.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      commit("common/setLoading", false, { root: true });
      throw error;
    }
  },
};
