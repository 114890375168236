import HttpService from "../http";

interface HttpConfig {
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  url: string;
  data?: any;
}

interface GenericPayload {
  config?: Partial<HttpConfig>;
  data?: any;
  success?: string;
  enableLoading?: boolean;
  enableError?: boolean;
  commit?: string;
}

interface GetResourcePayload {
  resourceName: string;
  commit?: string;
}

interface CreateResourcePayload {
  resource: any;
}

interface UpsertResourcePayload {
  resourceName: string;
  resource: any;
}

interface UpdateResourcePayload {
  resourceName: string;
  resource: any;
  updateMask?: string;
}

interface DeleteResourcePayload {
  resourceName: string;
}

interface ListResourcePayload {
  queryString?: string;
  commit?: string;
}

export default class CrudService extends HttpService {
  public async genericMethod<T>(payload: GenericPayload): Promise<T> {
    const config: HttpConfig = {
      ...payload.config,
    } as HttpConfig;

    if (payload.data) {
      config.data = payload.data;
    }

    return this.httpCall<T>({
      config,
      success: payload.success,
      enableLoading: payload.enableLoading,
      enableError: payload.enableError,
      commit: payload.commit,
    });
  }

  public async getResource<T>(payload: GetResourcePayload): Promise<T> {
    return this.httpCall<T>({
      config: {
        method: "GET",
        url: `/${payload.resourceName}`,
      },
      success: "",
      enableLoading: false,
      enableError: false,
      commit: payload.commit,
    });
  }

  public async createResource<T>(
    route: string,
    resourceValue: string,
    payload: CreateResourcePayload
  ): Promise<T> {
    return this.httpCall<T>({
      config: {
        method: "POST",
        url: `/${route}`,
        data: payload.resource,
      },
      success: resourceValue ? `${resourceValue} created` : "",
      enableLoading: true,
      enableError: true,
      commit: "",
    });
  }

  public async upsertResource<T>(payload: UpsertResourcePayload): Promise<T> {
    return this.httpCall<T>({
      config: {
        method: "POST",
        url: `/${payload.resourceName}:upsert`,
        data: payload.resource,
      },
      success: "",
      enableLoading: false,
      enableError: false,
      commit: "",
    });
  }

  public async updateResource<T>(
    resourceValue: string,
    payload: UpdateResourcePayload
  ): Promise<T> {
    const updateMask = Object.keys(payload.resource);
    return this.httpCall<T>({
      config: {
        method: "PATCH",
        url: `/${payload.resourceName}?update_mask=${
          payload.updateMask ? payload.updateMask : updateMask.join(",")
        }`,
        data: payload.resource,
      },
      success: "",
      enableLoading: true,
      enableError: true,
      commit: "contactLists/setResource",
    });
  }

  public async deleteResource<T>(
    resourceValue: string,
    payload: DeleteResourcePayload
  ): Promise<T> {
    return this.httpCall<T>({
      config: {
        method: "DELETE",
        url: `/${payload.resourceName}`,
      },
      success: resourceValue ? `${resourceValue} deleted` : "Resource deleted",
      enableLoading: true,
      enableError: true,
      commit: "",
    });
  }

  public async listResource<T>(route: string, payload: ListResourcePayload): Promise<T> {
    let parameters = payload.queryString ? `${payload.queryString}` : "";
    return this.httpCall<T>({
      config: {
        method: "GET",
        url: `${route}?page_size=100${parameters}`,
      },
      success: "",
      enableLoading: false,
      enableError: false,
      commit: payload.commit,
    });
  }
}
