import { allPermissions } from "@/consts/configuration";
export var usePermissions = function usePermissions() {
  var $store = useStore();
  var hasNumbersListPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberPhoneNumberInstancesList);
  });
  var hasMessagesListPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.messagingMessagesList);
  });
  var hasVoiceCallsGetPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.voiceCallsGet);
  });
  var hasFlowFlowsListPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.flowFlowsList);
  });
  var hasMediaMediaDeletePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.mediaMediaDelete);
  });
  var hasMediaMediaUpdatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.mediaMediaUpdate);
  });
  var hasMediaMediaUploadPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.mediaMediaUpload);
  });
  var hasUsersViewPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.resourcemanagerProjectsGetIamPolicy);
  });
  var hasUsersUpdatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.resourcemanagerProjectsSetIamPolicy);
  });
  var getSigningKeyPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.getSigningKey);
  });
  var renewSigningKeyPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.renewSigningKey);
  });
  var hasBillingAccountDetailsUpdatePermission = computed(function () {
    return $store.getters["billingAccounts/checkBillingPermissions"](allPermissions.billingBillingAccountsUpdateAccountDetails);
  });
  var hasBillingAccountsGetAccountDetailsPermission = computed(function () {
    return $store.getters["billingAccounts/checkBillingPermissions"](allPermissions.billingBillingAccountsGetAccountDetails);
  });
  var hasPhoneNumberUpdatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberPhoneNumberInstancesUpdate);
  });
  var hasPhoneNumberCreatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberPhoneNumberInstancesCreate);
  });
  var hasFlowFlowsDeletePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.flowFlowsDelete);
  });
  var hasFlowFlowsCreatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.flowFlowsCreate);
  });
  var hasFlowFlowsUdatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.flowFlowsUpdate);
  });
  var hasFlowVersionsCreatePermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.flowVersionsCreate);
  });
  var hasFlowVersionsSaveDraftPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.flowVersionsSaveDraft);
  });
  var hasCallerreputationClisListPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.callerreputationClisList);
  });
  var hasVoiceSipDomainsListPermission = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.voiceSipDomainsList);
  });
  var hasNumberRegulatoryListingsCreate = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberRegulatoryListingsCreate);
  });
  var hasNumberRegulatoryListingsUpdate = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberRegulatoryListingsUpdate);
  });
  var hasNumberRegulatoryListingsDelete = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberRegulatoryListingsDelete);
  });
  var hasNumberRegulatoryListingsList = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberRegulatoryListingsList);
  });
  var hasNumberRegulatoryListingsGet = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.numberRegulatoryListingsGet);
  });
  var HAS_SMSMARKETING_CONTACTS_LIST = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTS_LIST);
  });
  var HAS_SMSMARKETING_CONTACTS_GET = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTS_GET);
  });
  var HAS_SMSMARKETING_CONTACTS_UPDATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTS_UPDATE);
  });
  var HAS_SMSMARKETING_CONTACTS_CREATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTS_CREATE);
  });
  var HAS_SMSMARKETING_CONTACTLISTS_LIST = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTLISTS_LIST);
  });
  var HAS_SMSMARKETING_CONTACTLISTS_GET = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTLISTS_GET);
  });
  var HAS_SMSMARKETING_CONTACTLISTS_UPDATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTLISTS_UPDATE);
  });
  var HAS_SMSMARKETING_CONTACTLISTS_CREATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CONTACTLISTS_CREATE);
  });
  var HAS_SMSMARKETING_CAMPAIGNS_LIST = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CAMPAIGNS_LIST);
  });
  var HAS_SMSMARKETING_CAMPAIGNS_GET = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CAMPAIGNS_GET);
  });
  var HAS_SMSMARKETING_CAMPAIGNS_UPDATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CAMPAIGNS_UPDATE);
  });
  var HAS_SMSMARKETING_CAMPAIGNS_CREATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.SMSMARKETING_CAMPAIGNS_CREATE);
  });
  var hasVoiceSipDomainsUpdate = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.voiceSipDomainsUpdate);
  });
  var HAS_NUMBERING_PORTINREQUESTS_CREATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.NUMBERING_PORTINREQUESTS_CREATE);
  });
  var HAS_NUMBERING_PORTINREQUESTS_UPDATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.NUMBERING_PORTINREQUESTS_UPDATE);
  });
  var HAS_BILLING_BUNDLES_GET = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.BILLING_BUNDLES_GET);
  });
  var HAS_BILLING_BUNDLES_SCALE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.BILLING_BUNDLES_SCALE);
  });
  var HAS_MESSAGING_GOMINTEGRATION_GET = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.messagingGomIntegrationsGet);
  });
  var HAS_MESSAGING_GOMINTEGRATION_CREATE = computed(function () {
    return $store.getters["user/checkPermissions"](allPermissions.messagingGomIntegrationsCreate);
  });
  return {
    HAS_BILLING_BUNDLES_GET: HAS_BILLING_BUNDLES_GET,
    HAS_BILLING_BUNDLES_SCALE: HAS_BILLING_BUNDLES_SCALE,
    HAS_NUMBERING_PORTINREQUESTS_UPDATE: HAS_NUMBERING_PORTINREQUESTS_UPDATE,
    HAS_NUMBERING_PORTINREQUESTS_CREATE: HAS_NUMBERING_PORTINREQUESTS_CREATE,
    hasVoiceSipDomainsUpdate: hasVoiceSipDomainsUpdate,
    HAS_SMSMARKETING_CONTACTS_LIST: HAS_SMSMARKETING_CONTACTS_LIST,
    HAS_SMSMARKETING_CONTACTS_GET: HAS_SMSMARKETING_CONTACTS_GET,
    HAS_SMSMARKETING_CONTACTS_UPDATE: HAS_SMSMARKETING_CONTACTS_UPDATE,
    HAS_SMSMARKETING_CONTACTS_CREATE: HAS_SMSMARKETING_CONTACTS_CREATE,
    HAS_SMSMARKETING_CONTACTLISTS_LIST: HAS_SMSMARKETING_CONTACTLISTS_LIST,
    HAS_SMSMARKETING_CONTACTLISTS_GET: HAS_SMSMARKETING_CONTACTLISTS_GET,
    HAS_SMSMARKETING_CONTACTLISTS_UPDATE: HAS_SMSMARKETING_CONTACTLISTS_UPDATE,
    HAS_SMSMARKETING_CONTACTLISTS_CREATE: HAS_SMSMARKETING_CONTACTLISTS_CREATE,
    HAS_SMSMARKETING_CAMPAIGNS_LIST: HAS_SMSMARKETING_CAMPAIGNS_LIST,
    HAS_SMSMARKETING_CAMPAIGNS_GET: HAS_SMSMARKETING_CAMPAIGNS_GET,
    HAS_SMSMARKETING_CAMPAIGNS_UPDATE: HAS_SMSMARKETING_CAMPAIGNS_UPDATE,
    HAS_SMSMARKETING_CAMPAIGNS_CREATE: HAS_SMSMARKETING_CAMPAIGNS_CREATE,
    hasNumberRegulatoryListingsCreate: hasNumberRegulatoryListingsCreate,
    hasNumberRegulatoryListingsUpdate: hasNumberRegulatoryListingsUpdate,
    hasNumberRegulatoryListingsDelete: hasNumberRegulatoryListingsDelete,
    hasNumberRegulatoryListingsList: hasNumberRegulatoryListingsList,
    hasNumberRegulatoryListingsGet: hasNumberRegulatoryListingsGet,
    hasNumbersListPermission: hasNumbersListPermission,
    hasMessagesListPermission: hasMessagesListPermission,
    hasVoiceCallsGetPermission: hasVoiceCallsGetPermission,
    hasFlowFlowsListPermission: hasFlowFlowsListPermission,
    hasMediaMediaDeletePermission: hasMediaMediaDeletePermission,
    hasMediaMediaUpdatePermission: hasMediaMediaUpdatePermission,
    hasMediaMediaUploadPermission: hasMediaMediaUploadPermission,
    hasUsersViewPermission: hasUsersViewPermission,
    hasUsersUpdatePermission: hasUsersUpdatePermission,
    getSigningKeyPermission: getSigningKeyPermission,
    renewSigningKeyPermission: renewSigningKeyPermission,
    hasBillingAccountDetailsUpdatePermission: hasBillingAccountDetailsUpdatePermission,
    hasBillingAccountsGetAccountDetailsPermission: hasBillingAccountsGetAccountDetailsPermission,
    hasPhoneNumberUpdatePermission: hasPhoneNumberUpdatePermission,
    hasFlowFlowsDeletePermission: hasFlowFlowsDeletePermission,
    hasFlowFlowsCreatePermission: hasFlowFlowsCreatePermission,
    hasFlowFlowsUdatePermission: hasFlowFlowsUdatePermission,
    hasFlowVersionsCreatePermission: hasFlowVersionsCreatePermission,
    hasFlowVersionsSaveDraftPermission: hasFlowVersionsSaveDraftPermission,
    hasCallerreputationClisListPermission: hasCallerreputationClisListPermission,
    hasVoiceSipDomainsListPermission: hasVoiceSipDomainsListPermission,
    hasPhoneNumberCreatePermission: hasPhoneNumberCreatePermission,
    HAS_MESSAGING_GOMINTEGRATION_GET: HAS_MESSAGING_GOMINTEGRATION_GET,
    HAS_MESSAGING_GOMINTEGRATION_CREATE: HAS_MESSAGING_GOMINTEGRATION_CREATE
  };
};