// Marketplace Routes
const AppsListings = () => import("@/views/Marketplace/AppsListings");
const AppsListingsView = () => import("@/views/Marketplace/AppsListingsView");
const ActiveApps = () => import("@/views/Marketplace/ActiveApps");
const ActiveAppsView = () => import("@/views/Marketplace/ActiveAppsView");

export default [
  {
    path: "/projects/:projectId/apps/listings",
    name: "AppsListings",
    component: AppsListings,
    meta: {
      pageTitle: "Marketplace",
      featureFlipping: {
        key: "marketplace.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
    },
  },
  {
    path: "/projects/:projectId/apps/listings/:listingId",
    name: "AppsListingsView",
    component: AppsListingsView,
    meta: {
      pageTitle: "Genesys Open Messaging",
      featureFlipping: {
        key: "marketplace.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
      backLink: "/apps/listings",
    },
  },
  {
    path: "/projects/:projectId/apps/active",
    name: "AppsActive",
    component: ActiveApps,
    meta: {
      pageTitle: "Active apps",
      featureFlipping: {
        key: "marketplace.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
      dispatchAction: "gomApp/listAll",
    },
  },
  {
    path: "/projects/:projectId/apps/active/:activeAppId",
    name: "AppsActiveView",
    component: ActiveAppsView,
    meta: {
      pageTitle: "Apps",
      featureFlipping: {
        key: "marketplace.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
      backLink: "/apps/active",
      dispatchAction: "gomApp/initializeActiveAppView",
    },
  },
];
