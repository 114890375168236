import { MessagingApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
import { CreatePayload, DeletePayload, UpdatePayload } from "@/models/apps";
const gomAppApi = new CrudService(MessagingApiUrl);

export default {
  async initializeActiveAppView({ dispatch }, to) {
    try {
      await dispatch("getResource", to);
      const phoneNumbers = await dispatch(
        "phoneNumberInstances/getPhoneNumbers",
        {
          projectId: to.params.projectId,
        },
        {
          root: true,
        }
      );
      return { phoneNumbers };
    } catch (error) {
      throw error;
    }
  },
  async listAll({ commit, state }, to) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${MessagingApiUrl}/projects/${to.params.projectId}/gomIntegrations?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.gomIntegrations);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setActiveApps", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getResource({}, to) {
    const payload = {
      resourceName: `projects/${to.params.projectId}/gomIntegrations/${to.params.activeAppId}`,
      commit: "gomApp/setResource",
    };
    return gomAppApi.getResource(payload);
  },
  async createResource({}, payload: CreatePayload) {
    return gomAppApi.createResource(
      `projects/${payload.projectId}/gomIntegrations?gom_integration_id=${payload.gomAppId}`,
      "App",
      payload
    );
  },
  async updateResource({}, payload: UpdatePayload) {
    return gomAppApi.updateResource("App", payload);
  },
  async deleteResource({}, payload: DeletePayload) {
    return gomAppApi.deleteResource("App", payload);
  },
};
