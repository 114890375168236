// @ts-check

import { VoiceApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";

export default {
  resetUsers({ commit, state }, payload) {
    commit("resetUsers", "");
  },
  async getAll({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${
            payload.sipDomainId
          }/users?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.users);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAllSipDomainUsers", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async createResource({ commit, state }, payload) {
    const url = `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/users?user_id=${payload.userId}`;
    try {
      const res = await httpClient.post(url, {
        password: payload.password,
      });
      return res;
    } catch (error) {
      if (error) {
        if (error.code !== 6) {
          throw new Error();
        } else {
          throw new Error(JSON.stringify(error.code));
        }
      } else {
        throw new Error();
      }
    }
  },
  async updateResource({ commit, state }, payload) {
    try {
      const res = await httpClient.patch(`${VoiceApiUrl}/${payload.resourceName}`, {
        password: payload.password,
      });
      return res;
    } catch (error) {
      throw error;
    }
  },
  async deleteResource({ commit, state }, payload) {
    try {
      const res = await httpClient.delete(`${VoiceApiUrl}/${payload.resourceName}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  updateSipDomainUsers({ commit, state }, payload) {
    commit("setAllSipDomainUsers", payload);
  },
  addUser({ commit, state }, payload) {
    commit("addSipDomainUser", payload);
  },
};
