<template>
  <v-dialog
    v-model="showPrompt"
    max-width="500"
    @click:outside="cancel"
  >
    <div class="en-modal">
      <div class="en-modal-header">
        <h4>You have unsaved changes</h4>
        <v-icon @click="cancel">mdi-close</v-icon>
      </div>
      <div class="en-modal-body">
        <p class="en-p">{{ message }}</p>
      </div>

      <div class="en-modal-actions">
        <v-spacer></v-spacer>
        <en-button
          class="mr-main"
          @click="cancel"
        >
          Cancel
        </en-button>
        <en-button
          :primary="true"
          text
          @click="confirm"
        >
          Confirm
        </en-button>
      </div>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps(["onConfirm", "onCancel", "show", "message"]);

const showPrompt = ref(false);

const confirm = () => {
  props.onConfirm(); // Invoke the confirmation callback
  showPrompt.value = false;
};

const cancel = () => {
  props.onCancel(); // Invoke the cancellation callback
  showPrompt.value = false;
};

watch(
  () => props.show,
  (newVal) => {
    showPrompt.value = newVal;
  }
);
</script>
