import { Module } from "vuex";
import { state as initialState } from "./state";
import mutations from "./mutations";
import actions from "./actions";
import { GomAppState, RootState } from "@/models/apps";

export const gomApp: Module<GomAppState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
