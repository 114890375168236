import { MutationTree } from "vuex";
import { CustomerInviteStateType, CustomerInvite } from "./types";

const mutations: MutationTree<CustomerInviteStateType> = {
  setCustomerInvites(state, customerInvites: CustomerInvite[]) {
    state.customerInvites = customerInvites;
  },
  setCustomerInvite(state, customerInvite: CustomerInvite) {
    state.customerInvite = customerInvite;
  },
};

export default mutations;
