import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    user: {
      // @ts-ignore
      project: state.user.project,
    },
  }),
});

import { createStore } from "vue2-helpers/vuex";
import aliases from "./aliases";
import analytics from "./analytics";
import billingAccounts from "./billingAccounts";
import calls from "./calls";
import campaigns from "./campaigns";
import checks from "./checks";
import clis from "./clis";
import comments from "./comments";
import common from "./common";
import contactLists from "./contactLists";
import contacts from "./contacts";
import flows from "./flows";
import iam from "./iam";
import invoices from "./invoices";
import media from "./media";
import messages from "./messages";
import paymentMethods from "./paymentMethods";
import payments from "./payments";
import phoneNumberInstances from "./phoneNumberInstances";
import phoneNumbers from "./phoneNumbers";
import sipDomains from "./sipDomains";
import subscriptions from "./subscriptions";
import unsubscribers from "./unsubscribers";
import user from "./user";
import users from "./users";
import regulatoryListings from "./regulatoryListings";
import portInRequests from "./portInRequests";
import bundles from "./bundles";
import appListings from "./marketplace/appListings";
import customerInvites from "./customerInvites";
import { gomApp } from "./marketplace/gomApp";
import { RootState } from "@/models/apps";

const store = createStore<RootState>({
  modules: {
    aliases,
    analytics,
    billingAccounts,
    calls,
    campaigns,
    checks,
    clis,
    comments,
    common,
    contactLists,
    contacts,
    flows,
    iam,
    invoices,
    media,
    messages,
    paymentMethods,
    payments,
    phoneNumberInstances,
    phoneNumbers,
    sipDomains,
    subscriptions,
    unsubscribers,
    user,
    users,
    regulatoryListings,
    portInRequests,
    bundles,
    gomApp,
    appListings,
    customerInvites,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
