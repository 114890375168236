import { render, staticRenderFns } from "./BaseCard.vue?vue&type=template&id=472e8514&scoped=true"
import script from "./BaseCard.vue?vue&type=script&setup=true&lang=ts"
export * from "./BaseCard.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./BaseCard.vue?vue&type=style&index=0&id=472e8514&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472e8514",
  null
  
)

export default component.exports