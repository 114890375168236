const SipDomainsList = () => import("@/views/SipDomains/SipDomainsList");
const SipDomainsView = () => import("@/views/SipDomains/SipDomainsView");

export default [
  {
    path: "/projects/:projectId/sipDomains/list",
    name: "SIP Domain List",
    component: SipDomainsList,
    meta: {
      pageTitle: "SIP domains list",
      featureFlipping: {
        key: "sipDomains.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "sipDomain",
      dispatchAction: "sipDomains/getSipDomains",
    },
  },
  {
    path: "/projects/:projectId/sipDomains/:sipDomainId/overview",
    name: "SIP Domain View",
    component: SipDomainsView,
    meta: {
      pageTitle: "View SIP domain",
      featureFlipping: {
        key: "sipDomains.view",
        default: false,
      },
      headerImg: "./header-analytics.png",
      back: true,
      backLink: "/sipDomains/list",
      layout: "LayoutMain",
      tabs: "sipDomainView",
      dispatchAction: "sipDomains/initializeSipDomainView",
      disableDispatchActionError: true,
    },
  },
];
