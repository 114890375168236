import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  confirmPasswordReset,
  getIdToken,
  getIdTokenResult,
  sendEmailVerification,
  applyActionCode,
  multiFactor,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
} from "firebase/auth";

import axios from "axios";
import {
  FirebaseApiKey,
  FirebaseAuthDomain,
  FirebaseProjectId,
  LocalApiUrl,
} from "@/consts/configuration";
import store from "@/store";

export default class FirebaseAuthProvider implements IAuthProvider {
  private firebase;
  private auth;
  private totpSecret;

  constructor() {
    const firebaseApp = initializeApp({
      apiKey: FirebaseApiKey,
      authDomain: FirebaseAuthDomain,
      projectId: FirebaseProjectId,
    });
    this.firebase = firebaseApp;
    this.auth = getAuth(this.firebase);
    this.totpSecret = null;
  }

  public async login(
    email: string,
    password: string,
    tenant: string,
    primary: boolean,
    otp: string
  ): Promise<User> {
    async function handleSuccessfulLogin() {
      const user = await getAuth().currentUser;
      // Get the claims for the user trying to login
      const tokenResult = await getIdTokenResult(user);
      const userClaim = tokenResult.claims.tid ? tokenResult.claims.tid : "enfonica";

      const res = await axios.post(`${LocalApiUrl}initializeConsole`, {
        hostName:
          process.env.NODE_ENV === "development" ? "console.stag.enfonica.com" : location.hostname, //"console.stag.enfonica.com"
        userClaim,
      });

      const userTenantConfig = res.data.userTenantConfig;

      let allowLogin = false;

      if (userClaim === tenant) {
        allowLogin = true;
      }

      if (primary && userClaim !== tenant && !userTenantConfig?.console?.domain) {
        allowLogin = true;
      }

      if (!allowLogin) {
        await signOut(this.auth);
        throw Error();
      }
      const token = await getIdToken(user);
      // Return the users email & token

      const tenantConfig = res.data.tenantConfig;
      // add the userTenantConfig on login
      store.commit("user/setTenantConfig", {
        ...tenantConfig,
        // @ts-ignore
        enabledFeatures: store.state.user.tenantConfig.enabledFeatures,
        userTenantConfig,
        tenantAdminRole: tokenResult.claims.tr,
      });

      return {
        email: user.email,
        token,
        ...user,
      };
    }

    try {
      // Log in the user with email and password
      await signInWithEmailAndPassword(this.auth, email, password);
      return handleSuccessfulLogin();
    } catch (error) {
      if (otp) {
        const mfaResolver = getMultiFactorResolver(this.auth, error);
        const enrolledFactors = mfaResolver.hints;
        const mfaEnrollmentId = enrolledFactors.find((el) => el.factorId === "totp").uid;
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
          mfaEnrollmentId,
          otp
        );

        if (error.code === "auth/multi-factor-auth-required") {
          const mfaResolver = getMultiFactorResolver(this.auth, error);
          await mfaResolver.resolveSignIn(multiFactorAssertion);

          return handleSuccessfulLogin();
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  public async getCurrentUser(): Promise<User> {
    try {
      // Get the current user
      const user = await this.auth.currentUser;
      // If there is no current user, return null
      if (!user) {
        return null;
      }
      const token = await getIdToken(user);
      // Return the users email & token
      return {
        email: user.email,
        token,
        ...user,
      };
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async requestPasswordReset(email: string): Promise<boolean> {
    try {
      await sendPasswordResetEmail(this.auth, email);
      // Return true if the password reset request was successful
      return true;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async changePassword(
    email: string,
    oldPassword: string,
    newPassword: string,
    otp: string
  ): Promise<boolean> {
    try {
      // Sign in the user before changing the password
      await signInWithEmailAndPassword(this.auth, email, oldPassword);
      await updatePassword(this.auth.currentUser, newPassword);
      // Return true if the password reset request was successful
      return true;
    } catch (error) {
      if (otp) {
        if (error.code === "auth/multi-factor-auth-required") {
          const mfaResolver = getMultiFactorResolver(getAuth(), error);
          const enrolledFactors = mfaResolver.hints;
          const mfaEnrollmentId = enrolledFactors.find((el) => el.factorId === "totp").uid;
          const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
            mfaEnrollmentId,
            otp
          );
          await mfaResolver.resolveSignIn(multiFactorAssertion);
          // Unenroll from TOTP MFA.
          await updatePassword(this.auth.currentUser, newPassword);
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

  public async confirmPasswordReset(code: string, newPassword: string): Promise<boolean> {
    try {
      // Sign in the user before changing the password
      await confirmPasswordReset(this.auth, code, newPassword);
      // Return true if the password reset request was successful
      return true;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async getToken(): Promise<string> {
    try {
      // Get the current user
      const user = this.auth.currentUser;
      // If there is no current user, return
      if (!user) {
        return "";
      }

      // If there is a current user, get their token
      // that always stays refreshed
      const token = await getIdToken(user);
      // Return the token
      return token;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async getClaims(): Promise<any> {
    try {
      // Get the current user
      const user = this.auth.currentUser;
      // If there is no current user, return
      if (!user) {
        return "";
      }

      // If there is a current user, get their token
      // that always stays refreshed
      const res = await getIdTokenResult(user);
      // Return the token
      return res.claims;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async logout(): Promise<boolean> {
    try {
      // Log out the current user
      await signOut(this.auth);
      // Return true if the sign out request was successful
      return true;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public stateObserver(callback): any {
    onAuthStateChanged(this.auth, (user) => {
      // Invoke the callback function with the user object as the argument
      callback(user);
    });
  }

  public async verifyEmail(): Promise<any> {
    try {
      // Log out the current user
      await sendEmailVerification(this.auth.currentUser);
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async confirmEmailVerification(code: string): Promise<any> {
    try {
      // Log out the current user
      await applyActionCode(this.auth, code);
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async cancelEnrollMfa(code: string): Promise<any> {
    try {
      // Confirm cancel of mfa enrollment
      await applyActionCode(this.auth, code);
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async getMultiFactors(): Promise<any> {
    try {
      // Get the current user
      const user = await this.auth.currentUser;
      // If there is no current user, return null
      if (!user) {
        return null;
      }
      const multiFactorInfo = multiFactor(user);
      // Return the users email & token
      return multiFactorInfo;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async generateTotpSecret(password): Promise<any> {
    try {
      // Reauthenticate the current user
      await signInWithEmailAndPassword(this.auth, this.auth.currentUser.email, password);
      const multiFactorSession = await multiFactor(this.auth.currentUser).getSession();
      // Generate the mfa secret
      this.totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      // Generate the qrcode uri
      const totpUri = this.totpSecret.generateQrCodeUrl(
        this.auth.currentUser.email,
        "Enfonica Console"
      );
      return { totpSecret: this.totpSecret, totpUri };
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async enrollUser(verificationCode: string): Promise<any> {
    try {
      // Finalize the enrollment.
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        this.totpSecret,
        verificationCode
      );
      await multiFactor(this.auth.currentUser).enroll(multiFactorAssertion, "Enfonica Console");
      return true;
    } catch (error) {
      // If there is an error, throw it
      throw error;
    }
  }

  public async unenrollUser(mfaEnrollmentId: string, password: string, otp: string): Promise<any> {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(mfaEnrollmentId, otp);

    try {
      // Reauthenticate the current user
      await signInWithEmailAndPassword(this.auth, this.auth.currentUser.email, password);
    } catch (error) {
      if (otp) {
        if (error.code === "auth/multi-factor-auth-required") {
          const mfaResolver = getMultiFactorResolver(getAuth(), error);
          await mfaResolver.resolveSignIn(multiFactorAssertion);
          // Unenroll from TOTP MFA.
          await multiFactor(this.auth.currentUser).unenroll(mfaEnrollmentId);
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
}
