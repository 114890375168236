<template>
  <div
    style="position: relative"
    :id="id"
    :class="[{ 'en-is-dark': dark }, `en-phone-number-input--${size}`]"
    class="en-base-component en-phone-number-input en-flex"
  >
    <EnSelect
      v-if="!noCountrySelector"
      ref="CountrySelector"
      :value="countryCode"
      :options="countriesSorted"
      search
      :position="position"
      :search-placeholder="t.countrySelectorSearchPlaceholder"
      :items-height="countriesHeight"
      :hint="shouldChooseCountry ? t.countrySelectorError : null"
      :size="size"
      :success="isValid && !noValidation"
      :error="asYouTypeNumber && !isValid"
      :disabled="disabled"
      :input-value="callingCode"
      :list-width="480"
      :config="{
        labelKey: 'dialCode',
        searchKey: 'name',
        valueKey: 'iso2',
      }"
      :color="color"
      class="country-selector"
      :class="{
        'no-padding-left': noFlags || !countryCode,
      }"
      @input="setCountryCode($event, true)"
    >
      <template #default="{ option }">
        <div class="en-flex en-align-center">
          <div
            v-if="!noFlags"
            class="country-selector__flag-container mr-2"
          >
            <v-img
              width="30"
              :src="`/countries/${option.iso2.toLowerCase()}.png`"
            />
          </div>
          <span
            v-if="showCodeOnList"
            class="country-selector__calling-code en-flex-fixed en-text-muted"
            :class="{
              'en-text-muted-dark': option.isSelected,
            }"
          >
            +{{ option.dialCode }}
          </span>
          <div
            style="font-size: 14px"
            class="en-dots-text en-flex-1 en-text-left en-text-color text-left"
            :class="{
              'en-text-white': option.isSelected,
            }"
          >
            {{ option.name }}
          </div>
        </div>
      </template>
    </EnSelect>

    <button
      v-if="countryCode && !noFlags && !noCountrySelector"
      class="en-phone-number-input__country-flag mr-2"
      tabindex="-1"
      @click="focusCountrySelector"
    >
      <v-img
        class=""
        width="34"
        height="20"
        :src="`/countries/${countryCode.toLowerCase()}.png`"
      />
    </button>
    <EnInput
      style="cursor: text !important; font-size: 14px !important"
      :customId="customId"
      :id="uniqueId ? `${uniqueId}_phone_number` : null"
      ref="PhoneNumberInput"
      :value="asYouTypeNumber"
      :placeholder="placeholder || t.phoneNumberLabel"
      :hint="hint || hintValue"
      :disabled="disabled"
      :size="size"
      :success="isValid && !noValidation"
      :error="asYouTypeNumber && !isValid"
      v-bind="$attrs"
      clearable
      :color="color"
      class="input-phone-number en-flex-1"
      :class="{
        'has-border-radius': noCountrySelector,
      }"
      @keydown="
        (e) => {
          lastKeyPressed = e.keyCode;
        }
      "
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @change="$emit('change', $event)"
      @clear="$emit('clear', $event)"
      @input="buildResults"
      @keydown.tab.prevent="$emit('tabdown', $event)"
    >
      <!-- Custom left icon -->
      <slot
        slot="icon-left"
        name="icon-left"
      />
      <!-- Custom right icon -->
      <slot
        slot="icon-right"
        name="icon-right"
      />
    </EnInput>
  </div>
</template>

<script lang="ts">
import {
  fetchCountryCode,
  browserLocale,
  getResultsFromPhoneNumber,
  getAsYouTypeFormat,
  isCountryAvailable,
} from "./utils";
import { countries } from "./constants/phoneCodeCountries";
import examples from "libphonenumber-js/examples.mobile.json";
import { getExampleNumber, getCountryCallingCode } from "libphonenumber-js";
import locales from "./constants/locales";
import EnInput from "./components/EnInput";
import EnSelect from "./components/EnSelect";

import uniqueId from "./mixins/uniqueId";

export default {
  name: "EnPhoneNumberInput",
  components: {
    EnInput,
    EnSelect,
  },
  mixins: [uniqueId],
  props: {
    value: {
      validator: (prop) => ["string", "number"].includes(typeof prop) || prop === null,
      default: null,
    },
    customId: { type: String, default: null },
    id: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    // set default phone number (Ex: `default-phone-number="0658585858"`)
    defaultPhoneNumber: { type: String, default: null },
    // set default country code (Ex: `default-country-code="FR"`)
    defaultCountryCode: { type: String, default: null },
    // Same as EnInput (options: `sm|md|lg`)
    size: { type: String, default: null },
    // Countries selected will be at the top of the list - Ex : `preferred-countries="['FR', 'BE', 'DE']`
    preferredCountries: { type: Array, default: null },
    // Only countries selected are in list - Ex : `only-countries="['FR', 'BE', 'DE']`
    onlyCountries: { type: Array, default: null },
    // Countries seleted are remove from the list - Ex : `ignored-countries="['FR', 'BE', 'DE']`
    ignoredCountries: { type: Array, default: Array },
    translations: { type: Object, default: null },
    // Remove the validation UI state (success border color)
    noValidation: { type: Boolean, default: false },
    // Remove flags in country selector
    noFlags: { type: Boolean, default: false },
    // Remove the number example from the label input
    noExample: { type: Boolean, default: false },
    // Change the height of country item in list
    countriesHeight: { type: Number, default: 30 },
    // Disable use of browser locale to init the country selector (usefull for Nuxt.JS)
    noUseBrowserLocale: { type: Boolean, default: false },
    // Fetch country code via https://ip2c.org/s - Network needed - (Do not use it with default-country-code options)
    fetchCountry: { type: Boolean, default: false },
    // The country selector is not shown, you can validate your phone number with the country code set
    noCountrySelector: { type: Boolean, default: false },
    // Show the country phone code in the list
    showCodeOnList: { type: Boolean, default: false },
    // Enable the dark mode
    dark: { type: Boolean, default: false },
    // Use color
    color: { type: String, default: "primary" },
    // Set placholder of phone number input
    placeholder: { type: String, default: null },
    // hint message shown on phone number text field
    hint: { type: String, default: null },
    // set the position of countries list (ex: `top`, `top right`, `bottom right`)
    position: { type: String, default: "left bottom" },
  },
  data() {
    return {
      results: {},
      countryCode: this.defaultCountryCode,
      lastKeyPressed: null,
      asYouTypeNumber: this.defaultPhoneNumber,
    };
  },
  computed: {
    t() {
      return {
        ...locales,
        ...this.translations,
      };
    },
    callingCode() {
      const { countryCode } = this;
      const getDialCode = (code) => {
        const result = this.countriesSorted.find((m) => m.iso2 === code);
        return result ? result.dialCode : null;
      };
      return countryCode
        ? `+${getDialCode(countryCode) || getCountryCallingCode(countryCode)}`
        : null;
    },
    // input states
    shouldChooseCountry() {
      return !this.countryCode && !!this.asYouTypeNumber;
    },
    isValid() {
      return this.results.isValid;
    },
    hasEmptyPhone() {
      const { asYouTypeNumber } = this;
      return asYouTypeNumber === "" || !asYouTypeNumber;
    },
    // hint values
    phoneNumberExample() {
      const { countryCode } = this;
      const phoneNumber = countryCode ? getExampleNumber(countryCode, examples) : null;
      return phoneNumber ? phoneNumber.formatNational() : null;
    },
    hintValue() {
      const { noExample, phoneNumberExample, hasEmptyPhone, isValid, t } = this;
      return noExample || !phoneNumberExample
        ? null
        : hasEmptyPhone || isValid
        ? null
        : `${t.example} ${phoneNumberExample}`;
    },
    // Countries list management
    countriesList() {
      return countries.filter((item) => !this.ignoredCountries.includes(item.iso2));
    },
    countriesFiltered() {
      const countriesCon = this.onlyCountries || this.preferredCountries;
      return countriesCon.map((country) =>
        this.countriesList.find((item) => item.iso2.includes(country))
      );
    },
    otherCountries() {
      return this.countriesList.filter((item) => !this.preferredCountries.includes(item.iso2));
    },
    countriesSorted() {
      return this.preferredCountries
        ? [...this.countriesFiltered, ...this.otherCountries]
        : this.onlyCountries
        ? this.countriesFiltered
        : this.countriesList;
    },
  },
  watch: {
    defaultPhoneNumber: {
      handler(phoneNumber, oldPhoneNumber) {
        if (phoneNumber === oldPhoneNumber) {
          return;
        }
        this.buildResults(phoneNumber);
      },
      immediate: true,
    },
    defaultCountryCode: {
      handler(newValue, oldValue) {
        if (!newValue || newValue === oldValue) {
          return;
        }
        this.setCountryCode(newValue);
      },
      immediate: true,
    },
    value: {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.buildResults(newValue);
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    try {
      const { defaultCountryCode, fetchCountry, noUseBrowserLocale, setCountryCode } = this;

      if (!this.defaultPhoneNumber && this.value) {
        this.buildResults(this.value);
      }

      if (defaultCountryCode && fetchCountry) {
        throw new Error(
          "EnPhoneNumberInput: Do not use 'fetch-country' and 'default-country-code' options in the same time"
        );
      }
      if (defaultCountryCode && noUseBrowserLocale) {
        throw new Error(
          "EnPhoneNumberInput: If you use a 'default-country-code', do not use 'no-use-browser-locale' options"
        );
      }
      if (defaultCountryCode) {
        return;
      }

      const locale = fetchCountry
        ? await fetchCountryCode()
        : noUseBrowserLocale
        ? null
        : await browserLocale();

      if (locale) {
        setCountryCode(locale);
      }
    } catch (err) {
      throw new Error(err);
    }
  },
  methods: {
    async buildResults(phoneNumber, noAutoUpdateCountryCode) {
      const { asYouTypeNumber, lastKeyPressed, countryCode, value } = this;
      const backSpacePressed = lastKeyPressed === 8;

      await this.$nextTick();

      const lastCharacOfPhoneNumber = asYouTypeNumber
        ? asYouTypeNumber.slice(asYouTypeNumber.length - 1)
        : false;
      if (backSpacePressed && lastCharacOfPhoneNumber && lastCharacOfPhoneNumber === ")") {
        this.asYouTypeNumber = asYouTypeNumber.slice(0, -1);
      }

      this.results = await getResultsFromPhoneNumber(phoneNumber, countryCode);

      this.asYouTypeNumber = phoneNumber; //phoneNumber;

      if (
        !noAutoUpdateCountryCode &&
        this.results &&
        this.results.countryCode &&
        countryCode !== this.results.countryCode
      ) {
        this.setCountryCode(this.results.countryCode);
      }

      // sent when the user tape
      // @arg Object with all parsed values
      this.$emit("update", this.results);

      const { isValid, e164 } = this.results;

      const valueToEmit = isValid ? e164 : this.asYouTypeNumber;

      if (!valueToEmit && valueToEmit === value) {
        return;
      }

      // sent when the user tape
      // @arg Phone number value formatted in e164 format (international format)
      this.$emit("input", valueToEmit);
    },

    async setCountryCode(locale, focusPhoneNumberInput) {
      const { buildResults, asYouTypeNumber } = this;
      const countryAvailable = isCountryAvailable(locale ? locale : "AU");
      if (focusPhoneNumberInput) {
        this.focusPhoneNumberInput();
        if (asYouTypeNumber && asYouTypeNumber.includes("+")) {
          this.asYouTypeNumber = null;
        }
      }
      if (countryAvailable && locale) {
        this.countryCode = locale;
        buildResults(this.asYouTypeNumber, true);
      }
    },

    async focusCountrySelector() {
      await this.$nextTick();
      this.$refs.CountrySelector.$el.querySelector("input").focus();
    },

    async focusPhoneNumberInput() {
      await this.$nextTick();
      this.$refs.PhoneNumberInput.$el.querySelector("input").focus();
    },
  },
};
</script>

<style lang="scss">
.en-input__input {
  border-radius: 4px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font: "Inter", sans-serif;
}

#uri_phone_number {
  cursor: text !important;
  font-size: 14px !important;
}

.en-phone-number-input {
  position: relative;
}

.en-phone-number-input__country-flag {
  position: absolute;
  bottom: 12px;
  left: 0.857rem;
  z-index: 2;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.en-phone-number-input .country-selector {
  flex: 0 0 9.857rem;
  width: 9.857rem;
  min-width: 9.857rem;
  max-width: 9.857rem;
}

.en-phone-number-input .country-selector.has-list-open,
.en-phone-number-input .country-selector:focus {
  z-index: 3;
}

.en-phone-number-input .country-selector.has-list-open ~ .en-phone-number-input__country-flag,
.en-phone-number-input .country-selector:focus ~ .en-phone-number-input__country-flag {
  z-index: 4;
}

.en-phone-number-input .country-selector .en-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.en-phone-number-input .country-selector .en-input.has-error {
  z-index: 1;
}

.en-phone-number-input .country-selector:not(.no-padding-left) .en-input__input {
  padding-left: 50px;
}

.en-phone-number-input .country-selector .en-select__options-list .en-input {
  border-top-right-radius: var(--en-border-radius);
  border-bottom-right-radius: var(--en-border-radius);
}

.en-phone-number-input .country-selector .en-select__options-list .en-input__input {
  padding-left: 12px;
}

.en-phone-number-input .country-selector__calling-code {
  width: 3.214rem;
}

.en-phone-number-input--sm .en-phone-number-input__country-flag {
  bottom: 0.357rem;
}

.en-phone-number-input--sm .en-phone-number-input__country-flag > div {
  height: 0.857rem;
}

.en-phone-number-input--sm .country-selector {
  flex: 0 0 8.929rem;
  width: 8.929rem;
  min-width: 8.929rem;
  max-width: 8.929rem;
}

.en-phone-number-input--lg .en-phone-number-input__country-flag {
  bottom: 0.643rem;
}

.en-phone-number-input--lg .country-selector {
  flex: 0 0 10.357rem;
  width: 10.357rem;
  min-width: 10.357rem;
  max-width: 10.357rem;
}

.en-phone-number-input .en-input.input-phone-number .en-input__input {
  transition: z-index 0ms;
}

.en-phone-number-input .en-input.input-phone-number:not(.has-border-radius) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -2px;
}

:root {
  --en-primary: #1e90ff;
  --en-primary-darken: #1873cc;
  --en-primary-alpha-05: rgba(30, 144, 255, 0.05);
  --en-primary-alpha-40: rgba(30, 144, 255, 0.4);
  --en-primary-alpha-50: rgba(30, 144, 255, 0.5);
  --en-primary-alpha-60: rgba(30, 144, 255, 0.6);
  --en-secondary: #1cd1a1;
  --en-secondary-darken: #16a780;
  --en-secondary-alpha-05: rgba(28, 209, 161, 0.05);
  --en-secondary-alpha-40: rgba(28, 209, 161, 0.4);
  --en-secondary-alpha-50: rgba(28, 209, 161, 0.5);
  --en-secondary-alpha-60: rgba(28, 209, 161, 0.6);
  --en-third: #c41af9;
  --en-third-darken: #9c14c7;
  --en-third-alpha-05: rgba(196, 26, 249, 0.05);
  --en-third-alpha-40: rgba(196, 26, 249, 0.4);
  --en-third-alpha-50: rgba(196, 26, 249, 0.5);
  --en-third-alpha-60: rgba(196, 26, 249, 0.6);
  --en-danger: #ff4500;
  --en-danger-darken: #cc3700;
  --en-danger-alpha-05: rgba(255, 69, 0, 0.05);
  --en-danger-alpha-40: rgba(255, 69, 0, 0.4);
  --en-danger-alpha-50: rgba(255, 69, 0, 0.5);
  --en-danger-alpha-60: rgba(255, 69, 0, 0.6);
  --en-success: #9acd32;
  --en-success-darken: #7ba428;
  --en-success-alpha-05: rgba(154, 205, 50, 0.05);
  --en-success-alpha-40: rgba(154, 205, 50, 0.4);
  --en-success-alpha-50: rgba(154, 205, 50, 0.5);
  --en-success-alpha-60: rgba(154, 205, 50, 0.6);
  --en-info: #17a2b8;
  --en-info-darken: #128193;
  --en-info-alpha-05: rgba(23, 162, 184, 0.05);
  --en-info-alpha-40: rgba(23, 162, 184, 0.4);
  --en-info-alpha-50: rgba(23, 162, 184, 0.5);
  --en-info-alpha-60: rgba(23, 162, 184, 0.6);
  --en-warning: #ffa300;
  --en-warning-darken: #cc8200;
  --en-warning-alpha-05: rgba(255, 163, 0, 0.05);
  --en-warning-alpha-40: rgba(255, 163, 0, 0.4);
  --en-warning-alpha-50: rgba(255, 163, 0, 0.5);
  --en-warning-alpha-60: rgba(255, 163, 0, 0.6);
  --en-light: #eee;
  --en-light-darken: #bebebe;
  --en-light-alpha-05: hsla(0, 0%, 93.3%, 0.05);
  --en-light-alpha-40: hsla(0, 0%, 93.3%, 0.4);
  --en-light-alpha-50: hsla(0, 0%, 93.3%, 0.5);
  --en-light-alpha-60: hsla(0, 0%, 93.3%, 0.6);
  --en-dark: #21222e;
  --en-dark-darken: #1a1b24;
  --en-dark-alpha-05: rgba(33, 34, 46, 0.05);
  --en-dark-alpha-40: rgba(33, 34, 46, 0.4);
  --en-dark-alpha-50: rgba(33, 34, 46, 0.5);
  --en-dark-alpha-60: rgba(33, 34, 46, 0.6);
  --en-grey: #999;
  --en-grey-darken: #7a7a7a;
  --en-grey-alpha-05: hsla(0, 0%, 60%, 0.05);
  --en-grey-alpha-40: hsla(0, 0%, 60%, 0.4);
  --en-grey-alpha-50: hsla(0, 0%, 60%, 0.5);
  --en-grey-alpha-60: hsla(0, 0%, 60%, 0.6);
  --en-default: #ccc;
  --en-default-darken: #a3a3a3;
  --en-default-alpha-05: hsla(0, 0%, 80%, 0.05);
  --en-default-alpha-40: hsla(0, 0%, 80%, 0.4);
  --en-default-alpha-50: hsla(0, 0%, 80%, 0.5);
  --en-default-alpha-60: hsla(0, 0%, 80%, 0.6);
  --en-black: #000;
  --en-black-darken: #000;
  --en-black-alpha-05: rgba(0, 0, 0, 0.05);
  --en-black-alpha-40: rgba(0, 0, 0, 0.4);
  --en-black-alpha-50: rgba(0, 0, 0, 0.5);
  --en-black-alpha-60: rgba(0, 0, 0, 0.6);
  --en-white: #fff;
  --en-white-darken: #ccc;
  --en-white-alpha-05: hsla(0, 0%, 100%, 0.05);
  --en-white-alpha-40: hsla(0, 0%, 100%, 0.4);
  --en-white-alpha-50: hsla(0, 0%, 100%, 0.5);
  --en-white-alpha-60: hsla(0, 0%, 100%, 0.6);
  --en-transparent: hsla(0, 0%, 100%, 0);
  --en-transparent-darken: #ccc;
  --en-transparent-alpha-05: transparent;
  --en-transparent-alpha-40: transparent;
  --en-transparent-alpha-50: transparent;
  --en-transparent-alpha-60: transparent;
  --en-disabled: #f2f2f2;
  --en-disabled-darken: #c1c1c1;
  --en-disabled-alpha-05: hsla(0, 0%, 94.9%, 0.05);
  --en-disabled-alpha-40: hsla(0, 0%, 94.9%, 0.4);
  --en-disabled-alpha-50: hsla(0, 0%, 94.9%, 0.5);
  --en-disabled-alpha-60: hsla(0, 0%, 94.9%, 0.6);
  --en-base-font-size: 14px;
  --en-base-font-weight: 400;
  --en-base-line-height: 1.5;
  --en-border-width: 2px;
  --en-border-radius: 4px;
  --en-text-color: #212121;
  --en-muted-color: rgba(0, 0, 0, 0.54);
  --en-placeholder-color: #a7a7a7;
  --en-icon-color: #dedede;
  --en-bg-color: #fff;
  --en-bg-color-light: #f2f2f2;
  --en-overlay-color: rgba(86, 87, 117, 0.7);
  --en-elevation: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  --en-border-color: #eee;
  --en-border-color-darken: #d6d6d6;
  --en-hover-color: #eee;
  --en-hover-color-darken: #d6d6d6;
  --en-disabled-color: #f2f2f2;
  --en-disabled-color-darken: #d9d9d9;
}

.en-base-component,
html {
  font-size: var(--en-base-font-size);
}

.en-base-component {
  font-family: var(--en-base-font-family);
  font-weight: var(--en-base-font-weight);
  line-height: var(--en-base-line-height);
  -webkit-font-kerning: normal;
  font-kerning: normal;
}

.en-base-component,
.en-base-component *,
.en-base-component :after,
.en-base-component :before {
  box-sizing: border-box;
}

.en-base-component:not(.en-btn) {
  color: var(--en-text-color);
}

.en-arrow-icon {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.en-arrow-icon path.arrow {
  fill: var(--en-text-color);
}

.en-arrow-icon.is-white path.arrow {
  fill: #fff;
}

.en-arrow-icon.up {
  transform: rotate(180deg);
}

.en-arrow-icon.right {
  transform: rotate(-90deg);
}

.en-arrow-icon.left {
  transform: rotate(90deg);
}

.en-input {
  position: relative;
  background-color: var(--en-bg-color);
  transition: all 0.3s ease-in-out;
  height: 3rem;
  min-height: 3rem;
}

.en-input.is-textarea {
  min-height: 9.375rem !important;
}

.en-input__label {
  position: absolute;
  top: 0.125rem;
  cursor: pointer;
  outline: none;
  left: 0.8571rem;
  transform: translateY(25%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-size: 0.786rem;
  color: var(--en-placeholder-color);
}

.en-input__input {
  cursor: pointer;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  padding: 0 0.8571rem;
  color: var(--en-text-color);
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  z-index: 0;
  background-color: transparent;
  display: block;
  height: 100%;
}

.en-input__input.en-textarea {
  font-family: var(--en-base-font-family);
  font-weight: var(--en-base-font-weight);
  font-size: var(--en-base-font-size);
  line-height: var(--en-base-line-height);
  -webkit-font-kerning: normal;
  font-kerning: normal;
  padding: 0.5714rem 0.8571rem 0 0.8571rem;
  resize: vertical;
}

.en-input__input::-moz-placeholder {
  color: var(--en-placeholder-color);
}

.en-input__input:-ms-input-placeholder {
  color: var(--en-placeholder-color);
}

.en-input__input::placeholder {
  color: var(--en-placeholder-color);
}

.en-input__icon {
  position: absolute;
  z-index: 1;
  color: var(--en-icon-color);
  font-size: 1.286rem;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.en-input__icon.left {
  left: 0.5714rem;
}

.en-input__icon.right {
  right: 0.5714rem;
}

.en-input__toggle-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.5714rem;
  margin: auto 0;
  width: 1.714rem;
  height: 1.714rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
  color: var(--en-icon-color);
  border-radius: 1.714rem;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
}

.en-input__toggle-btn:focus {
  outline: none;
}

.en-input__toggle-btn__icon {
  position: relative;
  font-size: 1.286rem;
}

.en-input__toggle-btn.has-right-icon,
.en-input__toggle-btn.password.has-clear-btn {
  right: 2.857rem;
}

.en-input__toggle-btn.password.has-clear-btn.has-right-icon {
  right: 5rem;
}

.en-input__toggle-btn:hover {
  color: #fff;
  background-color: var(--en-icon-color);
}

.en-input.has-left-icon .en-input__input {
  padding-left: 2.857rem;
}

.en-input.has-left-icon .en-input__label {
  left: 2.857rem;
}

.en-input.has-1-right-icon .en-input__input {
  padding-right: 2.5rem;
}

.en-input.has-2-right-icon .en-input__input {
  padding-right: 4.643rem;
}

.en-input.has-3-right-icon .en-input__input {
  padding-right: 6.786rem;
}

.en-input.has-hint .en-input__label,
.en-input.has-value .en-input__label {
  opacity: 1;
  transform: translateY(0);
}

.en-input.has-hint:not(.has-no-label).is-textarea,
.en-input.has-value:not(.has-no-label).is-textarea {
  padding-top: 1.429rem;
}

.en-input.has-hint:not(.has-no-label) .en-input__input,
.en-input.has-value:not(.has-no-label) .en-input__input {
  // padding-top: 1rem;
}

.en-input.has-hint:not(.has-no-label) .en-input__input.en-textarea,
.en-input.has-value:not(.has-no-label) .en-input__input.en-textarea {
  transition: all 0ms;
  padding-top: 0;
  min-height: calc(7.946rem - var(--en-border-width) * 2);
}

.en-input.is-disabled {
  cursor: not-allowed;
  background-color: var(--en-disabled-color);
  border-color: var(--en-border-color);
}

.en-input.is-disabled:focus,
.en-input.is-disabled:hover {
  border-color: var(--en-border-color);
}

.en-input.is-disabled .en-input__input {
  color: var(--en-disabled-color-darken);
}

.en-input.is-disabled .en-input__input::-moz-placeholder {
  color: var(--en-disabled-color-darken);
}

.en-input.is-disabled .en-input__input:-ms-input-placeholder {
  color: var(--en-disabled-color-darken);
}

.en-input.is-disabled .en-input__input::placeholder {
  color: var(--en-disabled-color-darken);
}

.en-input.is-disabled .en-input__input,
.en-input.is-disabled .en-input__label,
.en-input.is-disabled .en-input__toggle__arrow {
  cursor: not-allowed;
  color: var(--en-disabled-color-darken);
}

.en-input--sm {
  height: 2.571rem;
  min-height: 2.571rem;
}

.en-input--sm .en-input__input {
  font-size: 0.857rem;
}

.en-input--sm .en-input__label {
  font-size: 0.714rem;
  top: 0.0625rem;
}

.en-input--sm.has-value:not(.has-no-label) .en-input__input {
  padding-top: 0.8571rem;
}

.en-input--sm.has-value:not(.has-no-label) .en-input__input.en-textarea {
  padding-top: 1.286rem;
}

.en-input--lg {
  height: 3.571rem;
  min-height: 3.571rem;
}

.en-input--lg .en-input__input {
  font-size: 1.143rem;
}

.en-input--lg .en-input__label {
  font-size: 1rem;
  top: 0.1875rem;
}

.en-input--lg.has-value:not(.has-no-label) .en-input__input {
  padding-top: 1.143rem;
}

.en-input--lg.has-value:not(.has-no-label) .en-input__input.en-textarea {
  padding-top: 1.857rem;
}

.en-input__loader {
  bottom: -var(--en-border-width) / 2;
  height: var(--en-border-width);
  left: var(--en-border-radius);
  width: calc(100% - var(--en-border-radius) * 2);
  position: absolute;
  overflow: hidden;
  border-radius: var(--en-border-radius);
}

.en-input__loader.en-textarea {
  bottom: 0.4286rem;
}

.en-input__loader__progress-bar {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 0.1429rem;
  -webkit-animation: loading 2s linear infinite;
  animation: loading 2s linear infinite;
}

.en-input--primary .en-input__loader__progress-bar {
  background-color: var(--en-primary);
}

.en-input--primary.is-focused.en-input--primary {
  border-color: var(--en-primary);
}

.en-input--primary.is-focused.en-input--primary:focus,
.en-input--primary.is-focused.en-input--primary:hover {
  border-color: var(--en-primary);
}

.en-input--primary.is-focused.en-input--primary .en-input__icon,
.en-input--primary.is-focused.en-input--primary .en-input__label {
  color: var(--en-primary);
}

.en-input--primary.is-focused.en-input--primary .en-input__icon path.arrow {
  fill: var(--en-primary);
}

.en-input--secondary .en-input__loader__progress-bar {
  background-color: var(--en-secondary);
}

.en-input--secondary.is-focused.en-input--secondary {
  border-color: var(--en-secondary);
}

.en-input--secondary.is-focused.en-input--secondary:focus,
.en-input--secondary.is-focused.en-input--secondary:hover {
  border-color: var(--en-secondary);
}

.en-input--secondary.is-focused.en-input--secondary .en-input__icon,
.en-input--secondary.is-focused.en-input--secondary .en-input__label {
  color: var(--en-secondary);
}

.en-input--secondary.is-focused.en-input--secondary .en-input__icon path.arrow {
  fill: var(--en-secondary);
}

.en-input--third .en-input__loader__progress-bar {
  background-color: var(--en-third);
}

.en-input--third.is-focused.en-input--third {
  border-color: var(--en-third);
}

.en-input--third.is-focused.en-input--third:focus,
.en-input--third.is-focused.en-input--third:hover {
  border-color: var(--en-third);
}

.en-input--third.is-focused.en-input--third .en-input__icon,
.en-input--third.is-focused.en-input--third .en-input__label {
  color: var(--en-third);
}

.en-input--third.is-focused.en-input--third .en-input__icon path.arrow {
  fill: var(--en-third);
}

.en-input--success .en-input__loader__progress-bar {
  background-color: var(--en-success);
}

.en-input--success.is-focused.en-input--success {
  border-color: var(--en-success) !important;
}

.en-input--success.is-focused.en-input--success:focus,
.en-input--success.is-focused.en-input--success:hover {
  border-color: var(--en-success);
}

.en-input--success.is-focused.en-input--success .en-input__icon,
.en-input--success.is-focused.en-input--success .en-input__label {
  color: var(--en-success);
}

.en-input--success.is-focused.en-input--success .en-input__icon path.arrow {
  fill: var(--en-success);
}

.en-input--danger .en-input__loader__progress-bar {
  background-color: var(--en-danger);
}

.en-input--danger.is-focused.en-input--danger {
  border-color: var(--en-danger);
}

.en-input--danger.is-focused.en-input--danger:focus,
.en-input--danger.is-focused.en-input--danger:hover {
  border-color: var(--en-danger);
}

.en-input--danger.is-focused.en-input--danger .en-input__icon,
.en-input--danger.is-focused.en-input--danger .en-input__label {
  color: var(--en-danger);
}

.en-input--danger.is-focused.en-input--danger .en-input__icon path.arrow {
  fill: var(--en-danger);
}

.en-input--grey .en-input__loader__progress-bar {
  background-color: var(--en-grey);
}

.en-input--grey.is-focused.en-input--grey {
  border-color: var(--en-grey);
}

.en-input--grey.is-focused.en-input--grey:focus,
.en-input--grey.is-focused.en-input--grey:hover {
  border-color: var(--en-grey);
}

.en-input--grey.is-focused.en-input--grey .en-input__icon,
.en-input--grey.is-focused.en-input--grey .en-input__label {
  color: var(--en-grey);
}

.en-input--grey.is-focused.en-input--grey .en-input__icon path.arrow {
  fill: var(--en-grey);
}

.en-input--info .en-input__loader__progress-bar {
  background-color: var(--en-info);
}

.en-input--info.is-focused.en-input--info {
  border-color: var(--en-info);
}

.en-input--info.is-focused.en-input--info:focus,
.en-input--info.is-focused.en-input--info:hover {
  border-color: var(--en-info);
}

.en-input--info.is-focused.en-input--info .en-input__icon,
.en-input--info.is-focused.en-input--info .en-input__label {
  color: var(--en-info);
}

.en-input--info.is-focused.en-input--info .en-input__icon path.arrow {
  fill: var(--en-info);
}

.en-input--warning .en-input__loader__progress-bar {
  background-color: var(--en-warning);
}

.en-input--warning.is-focused.en-input--warning {
  border-color: var(--en-warning);
}

.en-input--warning.is-focused.en-input--warning:focus,
.en-input--warning.is-focused.en-input--warning:hover {
  border-color: var(--en-warning);
}

.en-input--warning.is-focused.en-input--warning .en-input__icon,
.en-input--warning.is-focused.en-input--warning .en-input__label {
  color: var(--en-warning);
}

.en-input--warning.is-focused.en-input--warning .en-input__icon path.arrow {
  fill: var(--en-warning);
}

.en-input--light .en-input__loader__progress-bar {
  background-color: var(--en-light);
}

.en-input--light.is-focused.en-input--light {
  border-color: var(--en-light);
}

.en-input--light.is-focused.en-input--light:focus,
.en-input--light.is-focused.en-input--light:hover {
  border-color: var(--en-light);
}

.en-input--light.is-focused.en-input--light .en-input__icon,
.en-input--light.is-focused.en-input--light .en-input__label {
  color: var(--en-light);
}

.en-input--light.is-focused.en-input--light .en-input__icon path.arrow {
  fill: var(--en-light);
}

.en-input--dark .en-input__loader__progress-bar {
  background-color: var(--en-dark);
}

.en-input--dark.is-focused.en-input--dark {
  border-color: var(--en-dark);
}

.en-input--dark.is-focused.en-input--dark:focus,
.en-input--dark.is-focused.en-input--dark:hover {
  border-color: var(--en-dark);
}

.en-input--dark.is-focused.en-input--dark .en-input__icon,
.en-input--dark.is-focused.en-input--dark .en-input__label {
  color: var(--en-dark);
}

.en-input--dark.is-focused.en-input--dark .en-input__icon path.arrow {
  fill: var(--en-dark);
}

.en-input--default .en-input__loader__progress-bar {
  background-color: var(--en-default);
}

.en-input--default.is-focused.en-input--default {
  border-color: var(--en-default);
}

.en-input--default.is-focused.en-input--default:focus,
.en-input--default.is-focused.en-input--default:hover {
  border-color: var(--en-default);
}

.en-input--default.is-focused.en-input--default .en-input__icon,
.en-input--default.is-focused.en-input--default .en-input__label {
  color: var(--en-default);
}

.en-input--default.is-focused.en-input--default .en-input__icon path.arrow {
  fill: var(--en-default);
}

.en-input--disabled .en-input__loader__progress-bar {
  background-color: var(--en-disabled);
}

.en-input--disabled.is-focused.en-input--disabled {
  border-color: var(--en-disabled);
}

.en-input--disabled.is-focused.en-input--disabled:focus,
.en-input--disabled.is-focused.en-input--disabled:hover {
  border-color: var(--en-disabled);
}

.en-input--disabled.is-focused.en-input--disabled .en-input__icon,
.en-input--disabled.is-focused.en-input--disabled .en-input__label {
  color: var(--en-disabled);
}

.en-input--disabled.is-focused.en-input--disabled .en-input__icon path.arrow {
  fill: var(--en-disabled);
}

.en-input--white .en-input__loader__progress-bar {
  background-color: var(--en-white);
}

.en-input--white.is-focused.en-input--white {
  border-color: var(--en-white);
}

.en-input--white.is-focused.en-input--white:focus,
.en-input--white.is-focused.en-input--white:hover {
  border-color: var(--en-white);
}

.en-input--white.is-focused.en-input--white .en-input__icon,
.en-input--white.is-focused.en-input--white .en-input__label {
  color: var(--en-white);
}

.en-input--white.is-focused.en-input--white .en-input__icon path.arrow {
  fill: var(--en-white);
}

.en-input--black .en-input__loader__progress-bar {
  background-color: var(--en-black);
}

.en-input--black.is-focused.en-input--black {
  border-color: var(--en-black);
}

.en-input--black.is-focused.en-input--black:focus,
.en-input--black.is-focused.en-input--black:hover {
  border-color: var(--en-black);
}

.en-input--black.is-focused.en-input--black .en-input__icon,
.en-input--black.is-focused.en-input--black .en-input__label {
  color: var(--en-black);
}

.en-input--black.is-focused.en-input--black .en-input__icon path.arrow {
  fill: var(--en-black);
}

.en-input--transparent .en-input__loader__progress-bar {
  background-color: var(--en-transparent);
}

.en-input--transparent.is-focused.en-input--transparent {
  border-color: var(--en-transparent);
}

.en-input--transparent.is-focused.en-input--transparent:focus,
.en-input--transparent.is-focused.en-input--transparent:hover {
  border-color: var(--en-transparent);
}

.en-input--transparent.is-focused.en-input--transparent .en-input__icon,
.en-input--transparent.is-focused.en-input--transparent .en-input__label {
  color: var(--en-transparent);
}

.en-input--transparent.is-focused.en-input--transparent .en-input__icon path.arrow {
  fill: var(--en-transparent);
}

.en-input--light-grey .en-input__loader__progress-bar {
  background-color: var(--en-light-grey);
}

.en-input--light-grey.is-focused.en-input--light-grey {
  border-color: var(--en-light-grey);
}

.en-input--light-grey.is-focused.en-input--light-grey:focus,
.en-input--light-grey.is-focused.en-input--light-grey:hover {
  border-color: var(--en-light-grey);
}

.en-input--light-grey.is-focused.en-input--light-grey .en-input__icon,
.en-input--light-grey.is-focused.en-input--light-grey .en-input__label {
  color: var(--en-light-grey);
}

.en-input--light-grey.is-focused.en-input--light-grey .en-input__icon path.arrow {
  fill: var(--en-light-grey);
}

.en-input.is-focused.is-valid,
.en-input.is-valid {
  // border-color: var(--en-success) !important;
}

.en-input.is-focused.is-valid:focus,
.en-input.is-focused.is-valid:hover,
.en-input.is-valid:focus,
.en-input.is-valid:hover {
  // border-color: var(--en-success);
}

.en-input.is-focused.is-valid .en-input__icon,
.en-input.is-focused.is-valid .en-input__label,
.en-input.is-valid .en-input__icon,
.en-input.is-valid .en-input__label {
  // color: var(--en-success);
}

.en-input.is-focused.is-valid .en-input__icon path.arrow,
.en-input.is-valid .en-input__icon path.arrow {
  // fill: var(--en-success);
}

.en-input.has-warning,
.en-input.is-focused.has-warning {
  border-color: var(--en-warning);
}

.en-input.has-warning:focus,
.en-input.has-warning:hover,
.en-input.is-focused.has-warning:focus,
.en-input.is-focused.has-warning:hover {
  border-color: var(--en-warning);
}

.en-input.has-warning .en-input__icon,
.en-input.has-warning .en-input__label,
.en-input.is-focused.has-warning .en-input__icon,
.en-input.is-focused.has-warning .en-input__label {
  color: var(--en-warning);
}

.en-input.has-warning .en-input__icon path.arrow,
.en-input.is-focused.has-warning .en-input__icon path.arrow {
  fill: var(--en-warning);
}

.en-input.has-error,
.en-input.is-focused.has-error {
  border-color: var(--en-danger) !important;
}

.en-input.has-error:focus,
.en-input.has-error:hover,
.en-input.is-focused.has-error:focus,
.en-input.is-focused.has-error:hover {
  border-color: var(--en-danger);
}

.en-input.has-error .en-input__icon,
.en-input.has-error .en-input__label,
.en-input.is-focused.has-error .en-input__icon,
.en-input.is-focused.has-error .en-input__label {
  color: var(--en-danger);
}

.en-input.has-error .en-input__icon path.arrow,
.en-input.is-focused.has-error .en-input__icon path.arrow {
  fill: var(--en-danger);
}

.en-input input:-webkit-autofill,
.en-input input:-webkit-autofill:focus,
.en-input input:-webkit-autofill:hover,
.en-input select:-webkit-autofill,
.en-input select:-webkit-autofill:focus,
.en-input select:-webkit-autofill:hover,
.en-input textarea:-webkit-autofill,
.en-input textarea:-webkit-autofill:focus,
.en-input textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--en-text-color);
  box-shadow: 0 0 0 1000px var(--en-bg-color) inset;
}

.en-is-dark.en-input,
.en-is-dark .en-input {
  background-color: var(--en-bg-color-light);
}

.en-is-dark.en-loader .en-loader__anim div:after,
.en-is-dark .en-loader .en-loader__anim div:after {
  background-color: #fff;
}

.en-select {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.en-select--sm {
  font-size: 0.857rem;
}

.en-select--lg {
  font-size: 1.143rem;
}

.en-select__toggle {
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  outline: none;
  height: 24px;
}

.en-select__toggle__arrow {
  color: var(--en-icon-color);
}

.en-select__toggle__arrow .arrow {
  fill: var(--en-icon-color);
}

.en-select__tags {
  overflow-y: hidden;
  overflow-x: auto;
  position: absolute;
  top: 5px;
  left: 8px;
  bottom: 5px;
  z-index: 1;
  padding-left: 2px;
  max-width: calc(100% - 80px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.en-select__tags::-webkit-scrollbar {
  display: none;
}

.en-select__tags.en-left-offset {
  left: 40px;
}

.en-select__tag {
  margin-right: 4px;
  border-radius: calc(var(--en-border-radius) / 2);
  color: #fff;
  height: 100%;
  padding-right: 0.2857rem;
  padding-left: 0.5rem;
  box-shadow: none !important;
}

.en-select__tag__text {
  font-size: 0.875rem;
  margin-right: 5px;
  white-space: nowrap;
}

.en-select__tag__clear {
  font-size: 1.2rem;
}

.en-select__tag.en-btn--sm {
  font-size: 1.143rem;
}

.en-select__tag.en-btn--lg {
  padding-left: 0.7143rem;
  padding-right: 0.3571rem;
}

.en-select__tag.en-btn:before {
  border: none;
}

.en-select__options-list {
  z-index: 9;
  padding: 0;
  margin: 0;
  position: absolute;
  overflow: hidden;
  border-radius: var(--en-border-radius);
  background-color: var(--en-bg-color);
}

.en-select__options-list:not(.en-select__options-list--top) {
  top: 100%;
}

.en-select__options-list--top {
  bottom: 100%;
}

.en-select__options-list--right {
  right: 0;
}

.en-select__options-list__items {
  overflow-y: auto;
  overflow-x: hidden;
}

.en-select__options-list__item {
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1em;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--en-text-color);
  outline: none;
  transition: all 0.3s ease-in-out;
}

.en-select__options-list__item.keyboard-selected,
.en-select__options-list__item:hover {
  background-color: var(--en-hover-color);
}

.en-select__options-list__no-results i {
  font-size: 2.3rem;
}

.en-select.has-list-open .en-select__toggle {
  transform: rotate(180deg);
}

.en-select--primary .en-select__options-list__item.selected {
  background-color: var(--en-primary);
}

.en-select--primary .en-select__options-list__item.selected:hover {
  background-color: var(--en-primary);
}

.en-select--primary .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-primary);
}

.en-select--secondary .en-select__options-list__item.selected {
  background-color: var(--en-secondary);
}

.en-select--secondary .en-select__options-list__item.selected:hover {
  background-color: var(--en-secondary);
}

.en-select--secondary .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-secondary);
}

.en-select--third .en-select__options-list__item.selected {
  background-color: var(--en-third);
}

.en-select--third .en-select__options-list__item.selected:hover {
  background-color: var(--en-third);
}

.en-select--third .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-third);
}

.en-select--success .en-select__options-list__item.selected {
  background-color: var(--en-success);
}

.en-select--success .en-select__options-list__item.selected:hover {
  background-color: var(--en-success);
}

.en-select--success .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-success);
}

.en-select--danger .en-select__options-list__item.selected {
  background-color: var(--en-danger);
}

.en-select--danger .en-select__options-list__item.selected:hover {
  background-color: var(--en-danger);
}

.en-select--danger .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-danger);
}

.en-select--grey .en-select__options-list__item.selected {
  background-color: var(--en-grey);
}

.en-select--grey .en-select__options-list__item.selected:hover {
  background-color: var(--en-grey);
}

.en-select--grey .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-grey);
}

.en-select--info .en-select__options-list__item.selected {
  background-color: var(--en-info);
}

.en-select--info .en-select__options-list__item.selected:hover {
  background-color: var(--en-info);
}

.en-select--info .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-info);
}

.en-select--warning .en-select__options-list__item.selected {
  background-color: var(--en-warning);
}

.en-select--warning .en-select__options-list__item.selected:hover {
  background-color: var(--en-warning);
}

.en-select--warning .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-warning);
}

.en-select--light .en-select__options-list__item.selected {
  background-color: var(--en-light);
}

.en-select--light .en-select__options-list__item.selected:hover {
  background-color: var(--en-light);
}

.en-select--light .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-light);
}

.en-select--dark .en-select__options-list__item.selected {
  background-color: var(--en-dark);
}

.en-select--dark .en-select__options-list__item.selected:hover {
  background-color: var(--en-dark);
}

.en-select--dark .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-dark);
}

.en-select--default .en-select__options-list__item.selected {
  background-color: var(--en-default);
}

.en-select--default .en-select__options-list__item.selected:hover {
  background-color: var(--en-default);
}

.en-select--default .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-default);
}

.en-select--disabled .en-select__options-list__item.selected {
  background-color: var(--en-disabled);
}

.en-select--disabled .en-select__options-list__item.selected:hover {
  background-color: var(--en-disabled);
}

.en-select--disabled .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-disabled);
}

.en-select--white .en-select__options-list__item.selected {
  background-color: var(--en-white);
}

.en-select--white .en-select__options-list__item.selected:hover {
  background-color: var(--en-white);
}

.en-select--white .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-white);
}

.en-select--black .en-select__options-list__item.selected {
  background-color: var(--en-black);
}

.en-select--black .en-select__options-list__item.selected:hover {
  background-color: var(--en-black);
}

.en-select--black .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-black);
}

.en-select--transparent .en-select__options-list__item.selected {
  background-color: var(--en-transparent);
}

.en-select--transparent .en-select__options-list__item.selected:hover {
  background-color: var(--en-transparent);
}

.en-select--transparent .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-transparent);
}

.en-select--light-grey .en-select__options-list__item.selected {
  background-color: var(--en-light-grey);
}

.en-select--light-grey .en-select__options-list__item.selected:hover {
  background-color: var(--en-light-grey);
}

.en-select--light-grey .en-select__options-list__item.selected.keyboard-selected {
  background-color: var(--en-light-grey);
}

.en-spinner.spinner-anim__white path {
  fill: #fff;
}

.en-transition-expand,
.en-transition-expand * {
  will-change: height;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

.en-bg-primary {
  background-color: var(--en-primary);
}

.en-bg-primary-after:after {
  background-color: var(--en-primary);
}

.en-bg-primary-before:before {
  background-color: var(--en-primary);
}

.en-bg-primary-transparency {
  background-color: var(--en-primary-alpha-50);
}

.en-bg-primary-after-transparency:after {
  background-color: var(--en-primary-alpha-50);
}

.en-bg-primary-before-transparency:before {
  background-color: var(--en-primary-alpha-50);
}

.en-bg-secondary {
  background-color: var(--en-secondary);
}

.en-bg-secondary-after:after {
  background-color: var(--en-secondary);
}

.en-bg-secondary-before:before {
  background-color: var(--en-secondary);
}

.en-bg-secondary-transparency {
  background-color: var(--en-secondary-alpha-50);
}

.en-bg-secondary-after-transparency:after {
  background-color: var(--en-secondary-alpha-50);
}

.en-bg-secondary-before-transparency:before {
  background-color: var(--en-secondary-alpha-50);
}

.en-bg-third {
  background-color: var(--en-third);
}

.en-bg-third-after:after {
  background-color: var(--en-third);
}

.en-bg-third-before:before {
  background-color: var(--en-third);
}

.en-bg-third-transparency {
  background-color: var(--en-third-alpha-50);
}

.en-bg-third-after-transparency:after {
  background-color: var(--en-third-alpha-50);
}

.en-bg-third-before-transparency:before {
  background-color: var(--en-third-alpha-50);
}

.en-bg-success {
  background-color: var(--en-success);
}

.en-bg-success-after:after {
  background-color: var(--en-success);
}

.en-bg-success-before:before {
  background-color: var(--en-success);
}

.en-bg-success-transparency {
  background-color: var(--en-success-alpha-50);
}

.en-bg-success-after-transparency:after {
  background-color: var(--en-success-alpha-50);
}

.en-bg-success-before-transparency:before {
  background-color: var(--en-success-alpha-50);
}

.en-bg-danger {
  background-color: var(--en-danger);
}

.en-bg-danger-after:after {
  background-color: var(--en-danger);
}

.en-bg-danger-before:before {
  background-color: var(--en-danger);
}

.en-bg-danger-transparency {
  background-color: var(--en-danger-alpha-50);
}

.en-bg-danger-after-transparency:after {
  background-color: var(--en-danger-alpha-50);
}

.en-bg-danger-before-transparency:before {
  background-color: var(--en-danger-alpha-50);
}

.en-bg-grey {
  background-color: var(--en-grey);
}

.en-bg-grey-after:after {
  background-color: var(--en-grey);
}

.en-bg-grey-before:before {
  background-color: var(--en-grey);
}

.en-bg-grey-transparency {
  background-color: var(--en-grey-alpha-50);
}

.en-bg-grey-after-transparency:after {
  background-color: var(--en-grey-alpha-50);
}

.en-bg-grey-before-transparency:before {
  background-color: var(--en-grey-alpha-50);
}

.en-bg-info {
  background-color: var(--en-info);
}

.en-bg-info-after:after {
  background-color: var(--en-info);
}

.en-bg-info-before:before {
  background-color: var(--en-info);
}

.en-bg-info-transparency {
  background-color: var(--en-info-alpha-50);
}

.en-bg-info-after-transparency:after {
  background-color: var(--en-info-alpha-50);
}

.en-bg-info-before-transparency:before {
  background-color: var(--en-info-alpha-50);
}

.en-bg-warning {
  background-color: var(--en-warning);
}

.en-bg-warning-after:after {
  background-color: var(--en-warning);
}

.en-bg-warning-before:before {
  background-color: var(--en-warning);
}

.en-bg-warning-transparency {
  background-color: var(--en-warning-alpha-50);
}

.en-bg-warning-after-transparency:after {
  background-color: var(--en-warning-alpha-50);
}

.en-bg-warning-before-transparency:before {
  background-color: var(--en-warning-alpha-50);
}

.en-bg-light {
  background-color: var(--en-light);
}

.en-bg-light-after:after {
  background-color: var(--en-light);
}

.en-bg-light-before:before {
  background-color: var(--en-light);
}

.en-bg-light-transparency {
  background-color: var(--en-light-alpha-50);
}

.en-bg-light-after-transparency:after {
  background-color: var(--en-light-alpha-50);
}

.en-bg-light-before-transparency:before {
  background-color: var(--en-light-alpha-50);
}

.en-bg-dark {
  background-color: var(--en-dark);
}

.en-bg-dark-after:after {
  background-color: var(--en-dark);
}

.en-bg-dark-before:before {
  background-color: var(--en-dark);
}

.en-bg-dark-transparency {
  background-color: var(--en-dark-alpha-50);
}

.en-bg-dark-after-transparency:after {
  background-color: var(--en-dark-alpha-50);
}

.en-bg-dark-before-transparency:before {
  background-color: var(--en-dark-alpha-50);
}

.en-bg-default {
  background-color: var(--en-default);
}

.en-bg-default-after:after {
  background-color: var(--en-default);
}

.en-bg-default-before:before {
  background-color: var(--en-default);
}

.en-bg-default-transparency {
  background-color: var(--en-default-alpha-50);
}

.en-bg-default-after-transparency:after {
  background-color: var(--en-default-alpha-50);
}

.en-bg-default-before-transparency:before {
  background-color: var(--en-default-alpha-50);
}

.en-bg-disabled {
  background-color: var(--en-disabled);
}

.en-bg-disabled-after:after {
  background-color: var(--en-disabled);
}

.en-bg-disabled-before:before {
  background-color: var(--en-disabled);
}

.en-bg-disabled-transparency {
  background-color: var(--en-disabled-alpha-50);
}

.en-bg-disabled-after-transparency:after {
  background-color: var(--en-disabled-alpha-50);
}

.en-bg-disabled-before-transparency:before {
  background-color: var(--en-disabled-alpha-50);
}

.en-bg-white {
  background-color: var(--en-white);
}

.en-bg-white-after:after {
  background-color: var(--en-white);
}

.en-bg-white-before:before {
  background-color: var(--en-white);
}

.en-bg-white-transparency {
  background-color: var(--en-white-alpha-50);
}

.en-bg-white-after-transparency:after {
  background-color: var(--en-white-alpha-50);
}

.en-bg-white-before-transparency:before {
  background-color: var(--en-white-alpha-50);
}

.en-bg-black {
  background-color: var(--en-black);
}

.en-bg-black-after:after {
  background-color: var(--en-black);
}

.en-bg-black-before:before {
  background-color: var(--en-black);
}

.en-bg-black-transparency {
  background-color: var(--en-black-alpha-50);
}

.en-bg-black-after-transparency:after {
  background-color: var(--en-black-alpha-50);
}

.en-bg-black-before-transparency:before {
  background-color: var(--en-black-alpha-50);
}

.en-bg-transparent {
  background-color: var(--en-transparent);
}

.en-bg-transparent-after:after {
  background-color: var(--en-transparent);
}

.en-bg-transparent-before:before {
  background-color: var(--en-transparent);
}

.en-bg-transparent-transparency {
  background-color: var(--en-transparent-alpha-50);
}

.en-bg-transparent-after-transparency:after {
  background-color: var(--en-transparent-alpha-50);
}

.en-bg-transparent-before-transparency:before {
  background-color: var(--en-transparent-alpha-50);
}

.en-bg-light-grey {
  background-color: var(--en-light-grey);
}

.en-bg-light-grey-after:after {
  background-color: var(--en-light-grey);
}

.en-bg-light-grey-before:before {
  background-color: var(--en-light-grey);
}

.en-bg-light-grey-transparency {
  background-color: var(--en-light-grey-alpha-50);
}

.en-bg-light-grey-after-transparency:after {
  background-color: var(--en-light-grey-alpha-50);
}

.en-bg-light-grey-before-transparency:before {
  background-color: var(--en-light-grey-alpha-50);
}

.en-bg-color {
  background-color: var(--en-bg-color);
}

.en-bg-overlay {
  background-color: var(--en-overlay-color);
}

.en-bg-color-light {
  background-color: var(--en-bg-color-light);
}

.en-border-radius {
  border-radius: var(--en-border-radius);
}

.en-border-top-radius {
  border-top-left-radius: var(--en-border-radius);
  border-top-right-radius: var(--en-border-radius);
}

.en-border-bottom-radius {
  border-bottom-left-radius: var(--en-border-radius);
  border-bottom-right-radius: var(--en-border-radius);
}

.en-border-color {
  color: rgba(0, 0, 0, 0.45) !important;
  border: 1px solid !important;
}

.en-border-color-hover:hover {
  border-color: var(--en-border-color-darken);
}

.en-border-color-focus:focus {
  border-color: var(--en-border-color-darken);
}

.en-border-color-active:active {
  border-color: var(--en-border-color-darken);
}

.en-border-color-visited:visited {
  border-color: var(--en-border-color-darken);
}

.en-no-border {
  border: none;
}

.en-border-primary {
  border-color: var(--en-primary);
}

.en-border-secondary {
  border-color: var(--en-secondary);
}

.en-border-third {
  border-color: var(--en-third);
}

.en-border-success {
  border-color: var(--en-success);
}

.en-border-danger {
  border-color: var(--en-danger);
}

.en-border-grey {
  border-color: var(--en-grey);
}

.en-border-info {
  border-color: var(--en-info);
}

.en-border-warning {
  border-color: var(--en-warning);
}

.en-border-light {
  border-color: var(--en-light);
}

.en-border-dark {
  border-color: var(--en-dark);
}

.en-border-default {
  border-color: var(--en-default);
}

.en-border-disabled {
  border-color: var(--en-disabled);
}

.en-border-white {
  border-color: var(--en-white);
}

.en-border-black {
  border-color: var(--en-black);
}

.en-border-transparent {
  border-color: var(--en-transparent);
}

.en-border-light-grey {
  border-color: var(--en-light-grey);
}

.en-border-dotted {
  border-style: dotted;
}

.en-border-top-dotted {
  border-top-style: dotted;
}

.en-border-left-dotted {
  border-left-style: dotted;
}

.en-border-bottom-dotted {
  border-bottom-style: dotted;
}

.en-border-right-dotted {
  border-right-style: dotted;
}

.en-border-dashed {
  border-style: dashed;
}

.en-border-top-dashed {
  border-top-style: dashed;
}

.en-border-left-dashed {
  border-left-style: dashed;
}

.en-border-bottom-dashed {
  border-bottom-style: dashed;
}

.en-border-right-dashed {
  border-right-style: dashed;
}

.en-border-solid {
  border-style: solid;
}

.en-border-top-solid {
  border-top-style: solid;
}

.en-border-left-solid {
  border-left-style: solid;
}

.en-border-bottom-solid {
  border-bottom-style: solid;
}

.en-border-right-solid {
  border-right-style: solid;
}

.en-border-double {
  border-style: double;
}

.en-border-top-double {
  border-top-style: double;
}

.en-border-left-double {
  border-left-style: double;
}

.en-border-bottom-double {
  border-bottom-style: double;
}

.en-border-right-double {
  border-right-style: double;
}

.en-border-groove {
  border-style: groove;
}

.en-border-top-groove {
  border-top-style: groove;
}

.en-border-left-groove {
  border-left-style: groove;
}

.en-border-bottom-groove {
  border-bottom-style: groove;
}

.en-border-right-groove {
  border-right-style: groove;
}

.en-border-ridge {
  border-style: ridge;
}

.en-border-top-ridge {
  border-top-style: ridge;
}

.en-border-left-ridge {
  border-left-style: ridge;
}

.en-border-bottom-ridge {
  border-bottom-style: ridge;
}

.en-border-right-ridge {
  border-right-style: ridge;
}

.en-border-inset {
  border-style: inset;
}

.en-border-top-inset {
  border-top-style: inset;
}

.en-border-left-inset {
  border-left-style: inset;
}

.en-border-bottom-inset {
  border-bottom-style: inset;
}

.en-border-right-inset {
  border-right-style: inset;
}

.en-border-outset {
  border-style: outset;
}

.en-border-top-outset {
  border-top-style: outset;
}

.en-border-left-outset {
  border-left-style: outset;
}

.en-border-bottom-outset {
  border-bottom-style: outset;
}

.en-border-right-outset {
  border-right-style: outset;
}

.en-border-none {
  border-style: none;
}

.en-border-top-none {
  border-top-style: none;
}

.en-border-left-none {
  border-left-style: none;
}

.en-border-bottom-none {
  border-bottom-style: none;
}

.en-border-right-none {
  border-right-style: none;
}

.en-border-hidden {
  border-style: hidden;
}

.en-border-top-hidden {
  border-top-style: hidden;
}

.en-border-left-hidden {
  border-left-style: hidden;
}

.en-border-bottom-hidden {
  border-bottom-style: hidden;
}

.en-border-right-hidden {
  border-right-style: hidden;
}

.en-border-0 {
  border-width: 0;
}

.en-border-top-0 {
  border-top-width: 0;
}

.en-border-left-0 {
  border-left-width: 0;
}

.en-border-bottom-0 {
  border-bottom-width: 0;
}

.en-border-right-0 {
  border-right-width: 0;
}

.en-border-1 {
  border-width: 1px;
}

.en-border-top-1 {
  border-top-width: 1px;
}

.en-border-left-1 {
  border-left-width: 1px;
}

.en-border-bottom-1 {
  border-bottom-width: 1px;
}

.en-border-right-1 {
  border-right-width: 1px;
}

.en-border-2 {
  border-width: 2px;
}

.en-border-top-2 {
  border-top-width: 2px;
}

.en-border-left-2 {
  border-left-width: 2px;
}

.en-border-bottom-2 {
  border-bottom-width: 2px;
}

.en-border-right-2 {
  border-right-width: 2px;
}

.en-border-3 {
  border-width: 3px;
}

.en-border-top-3 {
  border-top-width: 3px;
}

.en-border-left-3 {
  border-left-width: 3px;
}

.en-border-bottom-3 {
  border-bottom-width: 3px;
}

.en-border-right-3 {
  border-right-width: 3px;
}

.en-border-4 {
  border-width: 4px;
}

.en-border-top-4 {
  border-top-width: 4px;
}

.en-border-left-4 {
  border-left-width: 4px;
}

.en-border-bottom-4 {
  border-bottom-width: 4px;
}

.en-border-right-4 {
  border-right-width: 4px;
}

.en-border-5 {
  border-width: 5px;
}

.en-border-top-5 {
  border-top-width: 5px;
}

.en-border-left-5 {
  border-left-width: 5px;
}

.en-border-bottom-5 {
  border-bottom-width: 5px;
}

.en-border-right-5 {
  border-right-width: 5px;
}

.en-border-6 {
  border-width: 6px;
}

.en-border-top-6 {
  border-top-width: 6px;
}

.en-border-left-6 {
  border-left-width: 6px;
}

.en-border-bottom-6 {
  border-bottom-width: 6px;
}

.en-border-right-6 {
  border-right-width: 6px;
}

.en-border-8 {
  border-width: 8px;
}

.en-border-top-8 {
  border-top-width: 8px;
}

.en-border-left-8 {
  border-left-width: 8px;
}

.en-border-bottom-8 {
  border-bottom-width: 8px;
}

.en-border-right-8 {
  border-right-width: 8px;
}

.en-border-9 {
  border-width: 9px;
}

.en-border-top-9 {
  border-top-width: 9px;
}

.en-border-left-9 {
  border-left-width: 9px;
}

.en-border-bottom-9 {
  border-bottom-width: 9px;
}

.en-border-right-9 {
  border-right-width: 9px;
}

.en-border-10 {
  border-width: 10px;
}

.en-border-top-10 {
  border-top-width: 10px;
}

.en-border-left-10 {
  border-left-width: 10px;
}

.en-border-bottom-10 {
  border-bottom-width: 10px;
}

.en-border-right-10 {
  border-right-width: 10px;
}

.en-border-11 {
  border-width: 11px;
}

.en-border-top-11 {
  border-top-width: 11px;
}

.en-border-left-11 {
  border-left-width: 11px;
}

.en-border-bottom-11 {
  border-bottom-width: 11px;
}

.en-border-right-11 {
  border-right-width: 11px;
}

.en-border-12 {
  border-width: 12px;
}

.en-border-top-12 {
  border-top-width: 12px;
}

.en-border-left-12 {
  border-left-width: 12px;
}

.en-border-bottom-12 {
  border-bottom-width: 12px;
}

.en-border-right-12 {
  border-right-width: 12px;
}

.en-border-13 {
  border-width: 13px;
}

.en-border-top-13 {
  border-top-width: 13px;
}

.en-border-left-13 {
  border-left-width: 13px;
}

.en-border-bottom-13 {
  border-bottom-width: 13px;
}

.en-border-right-13 {
  border-right-width: 13px;
}

.en-border-14 {
  border-width: 14px;
}

.en-border-top-14 {
  border-top-width: 14px;
}

.en-border-left-14 {
  border-left-width: 14px;
}

.en-border-bottom-14 {
  border-bottom-width: 14px;
}

.en-border-right-14 {
  border-right-width: 14px;
}

.en-border-15 {
  border-width: 15px;
}

.en-border-top-15 {
  border-top-width: 15px;
}

.en-border-left-15 {
  border-left-width: 15px;
}

.en-border-bottom-15 {
  border-bottom-width: 15px;
}

.en-border-right-15 {
  border-right-width: 15px;
}

.en-border-16 {
  border-width: 16px;
}

.en-border-top-16 {
  border-top-width: 16px;
}

.en-border-left-16 {
  border-left-width: 16px;
}

.en-border-bottom-16 {
  border-bottom-width: 16px;
}

.en-border-right-16 {
  border-right-width: 16px;
}

.en-border-17 {
  border-width: 17px;
}

.en-border-top-17 {
  border-top-width: 17px;
}

.en-border-left-17 {
  border-left-width: 17px;
}

.en-border-bottom-17 {
  border-bottom-width: 17px;
}

.en-border-right-17 {
  border-right-width: 17px;
}

.en-border-18 {
  border-width: 18px;
}

.en-border-top-18 {
  border-top-width: 18px;
}

.en-border-left-18 {
  border-left-width: 18px;
}

.en-border-bottom-18 {
  border-bottom-width: 18px;
}

.en-border-right-18 {
  border-right-width: 18px;
}

.en-border-19 {
  border-width: 19px;
}

.en-border-top-19 {
  border-top-width: 19px;
}

.en-border-left-19 {
  border-left-width: 19px;
}

.en-border-bottom-19 {
  border-bottom-width: 19px;
}

.en-border-right-19 {
  border-right-width: 19px;
}

.en-border-20 {
  border-width: 20px;
}

.en-border-top-20 {
  border-top-width: 20px;
}

.en-border-left-20 {
  border-left-width: 20px;
}

.en-border-bottom-20 {
  border-bottom-width: 20px;
}

.en-border-right-20 {
  border-right-width: 20px;
}

.en-border {
  border-width: var(--en-border-width);
}

.en-border-top {
  border-top-width: var(--en-border-width);
}

.en-border-left {
  border-left-width: var(--en-border-width);
}

.en-border-bottom {
  border-bottom-width: var(--en-border-width);
}

.en-border-right {
  border-right-width: var(--en-border-width);
}

.en-hover-bg-color:hover {
  background-color: var(--en-hover-color);
}

.en-no-hover-bg:hover {
  background: transparent;
}

.en-hover-bg-primary:hover {
  background-color: var(--en-primary);
}

.en-hover-bg-secondary:hover {
  background-color: var(--en-secondary);
}

.en-hover-bg-third:hover {
  background-color: var(--en-third);
}

.en-hover-bg-success:hover {
  background-color: var(--en-success);
}

.en-hover-bg-danger:hover {
  background-color: var(--en-danger);
}

.en-hover-bg-grey:hover {
  background-color: var(--en-grey);
}

.en-hover-bg-info:hover {
  background-color: var(--en-info);
}

.en-hover-bg-warning:hover {
  background-color: var(--en-warning);
}

.en-hover-bg-light:hover {
  background-color: var(--en-light);
}

.en-hover-bg-dark:hover {
  background-color: var(--en-dark);
}

.en-hover-bg-default:hover {
  background-color: var(--en-default);
}

.en-hover-bg-disabled:hover {
  background-color: var(--en-disabled);
}

.en-hover-bg-white:hover {
  background-color: var(--en-white);
}

.en-hover-bg-black:hover {
  background-color: var(--en-black);
}

.en-hover-bg-transparent:hover {
  background-color: var(--en-transparent);
}

.en-hover-bg-light-grey:hover {
  background-color: var(--en-light-grey);
}

.en-no-focus-bg:focus {
  background-color: transparent;
}

.en-focus-bg-primary:focus {
  background-color: var(--en-primary);
}

.en-focus-bg-secondary:focus {
  background-color: var(--en-secondary);
}

.en-focus-bg-third:focus {
  background-color: var(--en-third);
}

.en-focus-bg-success:focus {
  background-color: var(--en-success);
}

.en-focus-bg-danger:focus {
  background-color: var(--en-danger);
}

.en-focus-bg-grey:focus {
  background-color: var(--en-grey);
}

.en-focus-bg-info:focus {
  background-color: var(--en-info);
}

.en-focus-bg-warning:focus {
  background-color: var(--en-warning);
}

.en-focus-bg-light:focus {
  background-color: var(--en-light);
}

.en-focus-bg-dark:focus {
  background-color: var(--en-dark);
}

.en-focus-bg-default:focus {
  background-color: var(--en-default);
}

.en-focus-bg-disabled:focus {
  background-color: var(--en-disabled);
}

.en-focus-bg-white:focus {
  background-color: var(--en-white);
}

.en-focus-bg-black:focus {
  background-color: var(--en-black);
}

.en-focus-bg-transparent:focus {
  background-color: var(--en-transparent);
}

.en-focus-bg-light-grey:focus {
  background-color: var(--en-light-grey);
}

.en-elevation {
  box-shadow: var(--en-elevation);
}

.en-no-elevation {
  box-shadow: none;
}

.en-fill-primary path {
  fill: var(--en-primary);
}

.en-fill-secondary path {
  fill: var(--en-secondary);
}

.en-fill-third path {
  fill: var(--en-third);
}

.en-fill-success path {
  fill: var(--en-success);
}

.en-fill-danger path {
  fill: var(--en-danger);
}

.en-fill-grey path {
  fill: var(--en-grey);
}

.en-fill-info path {
  fill: var(--en-info);
}

.en-fill-warning path {
  fill: var(--en-warning);
}

.en-fill-light path {
  fill: var(--en-light);
}

.en-fill-dark path {
  fill: var(--en-dark);
}

.en-fill-default path {
  fill: var(--en-default);
}

.en-fill-disabled path {
  fill: var(--en-disabled);
}

.en-fill-white path {
  fill: var(--en-white);
}

.en-fill-black path {
  fill: var(--en-black);
}

.en-fill-transparent path {
  fill: var(--en-transparent);
}

.en-fill-light-grey path {
  fill: var(--en-light-grey);
}

.en-top-0 {
  top: 0;
}

.en-left-0 {
  left: 0;
}

.en-bottom-0 {
  bottom: 0;
}

.en-right-0 {
  right: 0;
}

.en-top-1 {
  top: 1px;
}

.en-left-1 {
  left: 1px;
}

.en-bottom-1 {
  bottom: 1px;
}

.en-right-1 {
  right: 1px;
}

.en-top-2 {
  top: 2px;
}

.en-left-2 {
  left: 2px;
}

.en-bottom-2 {
  bottom: 2px;
}

.en-right-2 {
  right: 2px;
}

.en-top-3 {
  top: 3px;
}

.en-left-3 {
  left: 3px;
}

.en-bottom-3 {
  bottom: 3px;
}

.en-right-3 {
  right: 3px;
}

.en-top-4 {
  top: 4px;
}

.en-left-4 {
  left: 4px;
}

.en-bottom-4 {
  bottom: 4px;
}

.en-right-4 {
  right: 4px;
}

.en-top-5 {
  top: 5px;
}

.en-left-5 {
  left: 5px;
}

.en-bottom-5 {
  bottom: 5px;
}

.en-right-5 {
  right: 5px;
}

.en-top-6 {
  top: 6px;
}

.en-left-6 {
  left: 6px;
}

.en-bottom-6 {
  bottom: 6px;
}

.en-right-6 {
  right: 6px;
}

.en-top-8 {
  top: 8px;
}

.en-left-8 {
  left: 8px;
}

.en-bottom-8 {
  bottom: 8px;
}

.en-right-8 {
  right: 8px;
}

.en-top-9 {
  top: 9px;
}

.en-left-9 {
  left: 9px;
}

.en-bottom-9 {
  bottom: 9px;
}

.en-right-9 {
  right: 9px;
}

.en-top-10 {
  top: 10px;
}

.en-left-10 {
  left: 10px;
}

.en-bottom-10 {
  bottom: 10px;
}

.en-right-10 {
  right: 10px;
}

.en-top-11 {
  top: 11px;
}

.en-left-11 {
  left: 11px;
}

.en-bottom-11 {
  bottom: 11px;
}

.en-right-11 {
  right: 11px;
}

.en-top-12 {
  top: 12px;
}

.en-left-12 {
  left: 12px;
}

.en-bottom-12 {
  bottom: 12px;
}

.en-right-12 {
  right: 12px;
}

.en-top-13 {
  top: 13px;
}

.en-left-13 {
  left: 13px;
}

.en-bottom-13 {
  bottom: 13px;
}

.en-right-13 {
  right: 13px;
}

.en-top-14 {
  top: 14px;
}

.en-left-14 {
  left: 14px;
}

.en-bottom-14 {
  bottom: 14px;
}

.en-right-14 {
  right: 14px;
}

.en-top-15 {
  top: 15px;
}

.en-left-15 {
  left: 15px;
}

.en-bottom-15 {
  bottom: 15px;
}

.en-right-15 {
  right: 15px;
}

.en-top-16 {
  top: 16px;
}

.en-left-16 {
  left: 16px;
}

.en-bottom-16 {
  bottom: 16px;
}

.en-right-16 {
  right: 16px;
}

.en-top-17 {
  top: 17px;
}

.en-left-17 {
  left: 17px;
}

.en-bottom-17 {
  bottom: 17px;
}

.en-right-17 {
  right: 17px;
}

.en-top-18 {
  top: 18px;
}

.en-left-18 {
  left: 18px;
}

.en-bottom-18 {
  bottom: 18px;
}

.en-right-18 {
  right: 18px;
}

.en-top-19 {
  top: 19px;
}

.en-left-19 {
  left: 19px;
}

.en-bottom-19 {
  bottom: 19px;
}

.en-right-19 {
  right: 19px;
}

.en-top-20 {
  top: 20px;
}

.en-left-20 {
  left: 20px;
}

.en-bottom-20 {
  bottom: 20px;
}

.en-right-20 {
  right: 20px;
}

.en-over-hid {
  overflow: hidden;
}

.en-overflow-auto {
  overflow: auto;
}

.en-overflow-y-auto {
  overflow-y: auto;
}

.en-overflow-x-auto {
  overflow-x: auto;
}

.en-overflow-inherit {
  overflow: inherit;
}

.en-overflow-y-inherit {
  overflow-y: inherit;
}

.en-overflow-x-inherit {
  overflow-x: inherit;
}

.en-overflow-initial {
  overflow: initial;
}

.en-overflow-y-initial {
  overflow-y: initial;
}

.en-overflow-x-initial {
  overflow-x: initial;
}

.en-overflow-overlay {
  overflow: overlay;
}

.en-overflow-y-overlay {
  overflow-y: overlay;
}

.en-overflow-x-overlay {
  overflow-x: overlay;
}

.en-overflow-hidden {
  overflow: hidden;
}

.en-overflow-y-hidden {
  overflow-y: hidden;
}

.en-overflow-x-hidden {
  overflow-x: hidden;
}

.en-overflow-scroll {
  overflow: scroll;
}

.en-overflow-y-scroll {
  overflow-y: scroll;
}

.en-overflow-x-scroll {
  overflow-x: scroll;
}

.en-overflow-unset {
  overflow: unset;
}

.en-overflow-y-unset {
  overflow-y: unset;
}

.en-overflow-x-unset {
  overflow-x: unset;
}

.en-overflow-visible {
  overflow: visible;
}

.en-overflow-y-visible {
  overflow-y: visible;
}

.en-overflow-x-visible {
  overflow-x: visible;
}

.en-pos-r {
  position: relative;
}

.en-pos-a,
.en-position-absolute {
  position: absolute;
}

.en-position-relative {
  position: relative;
}

.en-position-fixed {
  position: fixed;
}

.en-position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.en-flex {
  display: flex;
}

.en-flex-inline {
  display: inline-flex;
}

.en-fluid {
  width: 100%;
}

.en-direction-column {
  flex-direction: column;
}

.en-direction-column-reverse {
  flex-direction: column-reverse;
}

.en-direction-row {
  flex-direction: row;
}

.en-direction-row-reverse {
  flex-direction: row-reverse;
}

.en-align-center {
  align-items: center;
}

.en-align-start {
  align-items: flex-start;
}

.en-align-end {
  align-items: flex-end;
}

.en-justify-start {
  justify-content: flex-start;
}

.en-justify-end {
  justify-content: flex-end;
}

.en-justify-center {
  justify-content: center;
}

.en-space-between {
  justify-content: space-between;
}

.en-space-around {
  justify-content: space-around;
}

.en-flex-center {
  justify-content: center;
  align-items: center;
}

.en-flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.en-flex-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.en-flex-wrap {
  flex-wrap: wrap;
}

.en-flex-grow {
  flex-grow: 1;
}

.en-flex-fill {
  flex: 0 1 auto;
}

.en-flex-fixed {
  flex: 0 0 auto;
}

.en-flex-1 {
  flex: 1;
}

.en-flex-0 {
  flex: 0 1 0;
}

.en-flex-10 {
  flex: 0 1 10%;
}

.en-flex-15 {
  flex: 0 1 15%;
}

.en-flex-16 {
  flex: 0 1 16.66%;
}

.en-flex-20 {
  flex: 0 1 20%;
}

.en-flex-25 {
  flex: 0 1 25%;
}

.en-flex-30 {
  flex: 0 1 30%;
}

.en-flex-33 {
  flex: 0 1 33.33%;
}

.en-flex-40 {
  flex: 0 1 40%;
}

.en-flex-50 {
  flex: 0 1 50%;
}

.en-flex-60 {
  flex: 0 1 60%;
}

.en-flex-70 {
  flex: 0 1 70%;
}

.en-flex-80 {
  flex: 0 1 80%;
}

.en-flex-90 {
  flex: 0 1 90%;
}

.en-flex-100 {
  flex: 0 1 100%;
}
</style>
