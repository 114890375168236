import { render, staticRenderFns } from "./EnListItem.vue?vue&type=template&id=2080a0ef"
import script from "./EnListItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./EnListItem.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports