import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import { allPermissions } from "@/consts/configuration";
import queryFilter from "@/helpers/queryFilter";
import { isEnabled } from "vue-feature-flipping";
export var useNavigation = function useNavigation() {
  var $route = useRoute();
  var $router = useRouter();
  var $store = useStore();
  function goBack() {
    var hasHistoryCount = $store.state.common.historyCount - window.history.length;
    if (hasHistoryCount === 0) {
      if ($route.meta.backLink) {
        $router.push("/projects/".concat($route.params.projectId).concat($route.meta.backLink));
      }
    } else {
      $router.go(-1);
    }
  }
  var isEnfonicaTenant = computed(function () {
    var _a, _b;
    return (_b = (_a = $store.state.user) === null || _a === void 0 ? void 0 : _a.tenantConfig) === null || _b === void 0 ? void 0 : _b.primary;
  });
  var projectId = computed(function () {
    return $route.params.projectId;
  });
  var childRouteId = computed(function () {
    return queryFilter().value;
  });
  var hasBillingAccountsGetAccountDetailsPermission = computed(function () {
    return $store.getters["billingAccounts/checkBillingPermissions"](allPermissions.billingBillingAccountsGetAccountDetails);
  });
  var hasBillingAccount = computed(function () {
    if (hasBillingAccountsGetAccountDetailsPermission.value) {
      return true;
    } else {
      return false;
    }
  });
  var tabs = computed(function () {
    return {
      numbers: [{
        label: "Overview",
        path: "#/projects/".concat(projectId.value, "/numbers/overview"),
        active: "numbers/overview",
        render: false
      }, {
        label: "My numbers",
        path: "#/projects/".concat(projectId.value, "/numbers/list"),
        active: "numbers/list",
        render: isEnabled("phoneNumberInstances.list", false)
      }, {
        label: "Commitments",
        path: "#/projects/".concat(projectId.value, "/numbers/commitments"),
        active: "numbers/commitments",
        render: isEnabled("bundles.list", false)
      }, {
        label: "Purchase",
        path: "#/projects/".concat(projectId.value, "/numbers/purchase"),
        active: "numbers/purchase",
        render: isEnabled("phoneNumbers.list", false)
      }, {
        label: "Regulatory listings",
        path: "#/projects/".concat(projectId.value, "/regulatoryListings/list"),
        active: "regulatoryListings/list",
        render: isEnabled("phoneNumbers.list", false)
      }, {
        label: "Port in requests",
        path: "#/projects/".concat(projectId.value, "/portInRequests/list"),
        active: "portInRequests/list",
        render: isEnabled("portInRequests.list", false)
      }],
      analytics: [{
        label: "Dashboard",
        path: "#/projects/".concat(projectId.value, "/analytics/dashboard"),
        active: "analytics/dashboard",
        render: true
      }, {
        label: "Reports",
        path: "#/projects/".concat(projectId.value, "/analytics/reports"),
        active: "analytics/reports",
        render: true
      }],
      voice: [{
        label: "Overview",
        path: "#/projects/".concat(projectId.value, "/voice/overview?date=%2522month%2522"),
        active: "voice/overview",
        render: true
      }, {
        label: "Calls",
        path: "#/projects/".concat(projectId.value, "/voice/list?filters=%255B%255D"),
        active: "voice/list",
        render: true
      }, {
        label: "Recordings",
        path: "#/projects/".concat(projectId.value, "/voice/recordings/list"),
        active: "recordings/list",
        render: true
      }],
      callDetails: [{
        label: "Overview",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/voice/").concat(childRouteId.value.params.callId, "/overview"),
        active: "/overview",
        render: true
      }],
      messageDetails: [{
        label: "Overview",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/sms/").concat(childRouteId.value.params.messageId, "/overview"),
        active: "/overview",
        render: true
      }],
      sms: [{
        label: "Overview",
        path: "#/projects/".concat(projectId.value, "/sms/overview?date=%2522month%2522"),
        active: "sms/overview",
        render: true
      }, {
        label: "Messages",
        path: "#/projects/".concat(projectId.value, "/sms/list?filters=%255B%255D"),
        active: "sms/list",
        render: true
      }, {
        label: "Unsubscribers",
        path: "#/projects/".concat(projectId.value, "/sms/unsubscribers"),
        active: "sms/unsubscribers",
        render: true
      }, {
        label: "Apps",
        path: "#/projects/".concat(projectId.value, "/apps/active"),
        active: "/apps",
        render: isEnabled("marketplace.list", false)
      }],
      flow: [{
        label: "Flows",
        path: "#/projects/".concat(projectId.value, "/flow/list"),
        active: "flow/list",
        render: true
      }],
      settings: [{
        label: "General",
        path: "#/projects/".concat(projectId.value, "/settings/general"),
        active: "settings/general",
        render: true
      }, {
        label: "Users",
        path: "#/projects/".concat(projectId.value, "/settings/users"),
        active: "settings/users",
        render: true
      }, {
        label: "Service Accounts",
        path: "#/projects/".concat(projectId.value, "/service-accounts/list"),
        active: "service-accounts",
        render: isEnabled("serviceAccounts.list", false)
      }],
      account: [{
        label: "Overview",
        path: "#/projects/".concat(projectId.value, "/settings/account"),
        active: "settings/account",
        render: true
      }],
      billing: [{
        label: "Overview",
        path: "#/projects/".concat(projectId.value, "/billing/overview"),
        active: "billing/overview",
        render: true
      }, {
        label: "Invoices",
        path: "#/projects/".concat(projectId.value, "/billing/invoices"),
        active: "billing/invoices",
        render: hasBillingAccount.value
      }, {
        label: "Payments",
        path: "#/projects/".concat(projectId.value, "/billing/payments"),
        active: "billing/payments",
        render: hasBillingAccount.value
      }, {
        label: "Users",
        path: "#/projects/".concat(projectId.value, "/billing/users"),
        active: "billing/users",
        render: hasBillingAccount.value
      }],
      numberEdit: [{
        label: "Overview",
        path: "",
        active: "numbers",
        render: true
      }],
      regulatoryListingView: [{
        label: "Overview",
        path: "",
        active: "regulatoryListing",
        render: true
      }],
      serviceAccountEdit: [{
        label: "Overview",
        path: "",
        active: "service-accounts/",
        render: true
      }],
      sipDomain: [{
        label: "SIP domains",
        path: "",
        active: "/list",
        render: true
      }],
      aliasView: [{
        label: "Overview",
        path: "",
        active: "/aliases/",
        render: true
      }],
      sipDomainView: [{
        label: "Overview",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/sipDomains/").concat(childRouteId.value.params.sipDomainId, "/overview"),
        active: "/overview",
        render: true
      }],
      clis: [{
        label: "Overview",
        path: "#/projects/".concat(projectId.value, "/clis/overview"),
        active: "clis/overview",
        render: true
      }, {
        label: "Caller IDs",
        path: "#/projects/".concat(projectId.value, "/clis/list"),
        active: "clis/list",
        render: true
      }, {
        label: "Social",
        path: "#/projects/".concat(projectId.value, "/clis/social"),
        active: "clis/social",
        render: true
      }, {
        label: "Checks",
        path: "#/projects/".concat(projectId.value, "/clis/checks"),
        active: "/checks",
        render: true
      }, {
        label: "Subscriptions",
        path: "#/projects/".concat(projectId.value, "/clis/subscriptions/list"),
        active: "/subscriptions",
        render: true
      }],
      clisView: [{
        label: "Overview",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/clis/").concat(childRouteId.value.params.cliId, "/overview"),
        active: "/overview",
        render: true
      }, {
        label: "Social",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/clis/").concat(childRouteId.value.params.cliId, "/social"),
        active: "/social",
        render: true
      }, {
        label: "Checks",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/clis/").concat(childRouteId.value.params.cliId, "/checks"),
        active: "/checks",
        render: true
      }],
      smsMarketing: [{
        label: "Overview",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/campaigns/overview?date=%2522month%2522"),
        active: "/overview",
        render: true
      }, {
        label: "Campaigns",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/campaigns/list"),
        active: "/campaigns/list",
        render: true
      }, {
        label: "Contacts",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/contacts/list"),
        active: "/contacts",
        render: true
      }, {
        label: "Contact lists",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/contactLists/list"),
        active: "/contactLists",
        render: true
      }],
      campaignCreate: [{
        label: "New campaign",
        // @ts-ignore
        path: "",
        active: "/create",
        render: true
      }],
      campaignView: [{
        label: "Overview",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/campaigns/").concat(childRouteId.value.params.campaignId, "/overview"),
        active: "/overview",
        render: true
      }, {
        label: "Links",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/campaigns/").concat(childRouteId.value.params.campaignId, "/links"),
        active: "/links",
        render: true
      }, {
        label: "Recipients",
        // @ts-ignore
        path: "#/projects/".concat(projectId.value, "/campaigns/").concat(childRouteId.value.params.campaignId, "/recipients"),
        active: "/recipients",
        render: true
      }],
      tenantCustomerInvites: [{
        label: "Manage",
        path: "",
        active: "/tenants/customerInvites",
        render: true
      }],
      tenantNumbers: [{
        label: "Manage",
        path: "",
        active: "/tenants/numbers",
        render: true
      }],
      tenantUsers: [{
        label: "Manage",
        path: "",
        active: "/tenants/users",
        render: true
      }],
      tenantPortInRequests: [{
        label: "Manage",
        path: "#/tenants/portInRequests/list",
        active: "/tenants/portInRequests/list",
        render: true
      }],
      tenantProjects: [{
        label: "Manage",
        path: "",
        active: "/tenants/projects",
        render: true
      }],
      PortInRequestsCreate: [{
        label: "New request",
        path: "",
        active: "/create",
        render: true
      }],
      PortInRequestsView: [{
        label: "Overview",
        path: "",
        active: "/overview",
        render: true
      }]
    };
  });
  return {
    goBack: goBack,
    tabs: tabs
  };
};