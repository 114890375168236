// const NumbersOverview = () => import("@/views/Numbers/NumbersOverview");
const NumbersList = () => import("@/views/Numbers/NumbersList");
const NumbersPurchase = () =>
  import(/* webpackChunkName: "numbersPurchase" */ "@/views/Numbers/NumbersPurchase");
const NumbersView = () => import("@/views/Numbers/NumbersView");
const NumbersCommitments = () => import("@/views/Numbers/NumbersCommitments");

export default [
  {
    path: "/projects/:projectId/numbers/commitments",
    name: "Numbers Commitments",
    component: NumbersCommitments,
    meta: {
      pageTitle: "Commitments",
      featureFlipping: {
        key: "bundles.list",
        default: false,
      },
      headerImg: "./header-numbers.png",
      layout: "LayoutMain",
      tabs: "numbers",
      back: false,
      dispatchAction: "bundles/listBundles",
    },
  },
  {
    path: "/projects/:projectId/numbers/list",
    name: "Numbers List",
    component: NumbersList,
    meta: {
      pageTitle: "My numbers",
      featureFlipping: {
        key: "phoneNumberInstances.list",
        default: false,
      },
      headerImg: "./header-numbers.png",
      layout: "LayoutMain",
      tabs: "numbers",
      dispatchAction: "phoneNumberInstances/initializeListView",
    },
  },
  {
    path: "/projects/:projectId/numbers/purchase",
    name: "Numbers Purchase",
    component: NumbersPurchase,
    meta: {
      pageTitle: "Purchase phone numbers",
      featureFlipping: {
        key: "phoneNumbers.list",
        default: false,
      },
      headerImg: "./header-numbers.png",
      layout: "LayoutMain",
      tabs: "numbers",
      dispatchAction: "phoneNumbers/listResource",
    },
  },
  {
    path: "/projects/:projectId/numbers/:phoneNumberId",
    name: "Number Edit",
    component: NumbersView,
    meta: {
      pageTitle: "View phone numbers",
      featureFlipping: {
        key: "phoneNumbers.view",
        default: false,
      },
      headerImg: "./header-numbers.png",
      layout: "LayoutMain",
      tabs: "numberEdit",
      back: true,
      backLink: "/numbers/list",
      number: true,
      dispatchAction: "phoneNumberInstances/initializePhoneNumberView",
    },
  },
];
