import { ActionTree } from "vuex";
import {
  CustomerInviteStateType,
  CreateCustomerInvitePayload,
  AcceptCustomerInvitePayload,
  CustomerInvite,
} from "./types";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
import { PlatformPublicApiUrl, LocalApiUrl } from "@/consts/configuration";

const API_URL = PlatformPublicApiUrl;
const invitesApi = new CrudService(API_URL);

const actions: ActionTree<CustomerInviteStateType, any> = {
  async createCustomerInvite({ rootState }, payload: CreateCustomerInvitePayload) {
    const tenantName = rootState.user.tenantConfig.userTenantConfig.name;
    try {
      const response = await httpClient.post(`${API_URL}/${tenantName}/customerInvites`, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getCustomerInvite({}, inviteName: string) {
    try {
      const response = await invitesApi.getResource({
        resourceName: `${inviteName}`,
        commit: "setCustomerInvite",
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async listCustomerInvites({ commit, rootState }, payload: {}) {
    const tenantName = rootState.user.tenantConfig.userTenantConfig.name;
    try {
      let params: string | undefined;
      const allInvites: CustomerInvite[] = [];

      do {
        const res = await httpClient.get(
          `${API_URL}/${tenantName}/customerInvites?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allInvites.push(...res.data.customerInvites);
        params = res.data.nextPageToken;
      } while (params);

      commit("setCustomerInvites", allInvites);
      return allInvites;
    } catch (error) {
      throw error;
    }
  },

  async updateCustomerInvite({ dispatch }, payload: any) {
    try {
      const response = await invitesApi.updateResource("Customer Invite", {
        resourceName: `${payload.customerInviteName}`,
        resource: payload.customerInvite,
      });
      await dispatch("listCustomerInvites");
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteCustomerInvite({ dispatch }, inviteName: string) {
    try {
      await invitesApi.deleteResource("Customer Invite", {
        resourceName: `${inviteName}`,
      });
      await dispatch("listCustomerInvites");
    } catch (error) {
      throw error;
    }
  },

  async sendCustomerInvite({}, inviteName: string) {
    try {
      const response = await httpClient.post(`${API_URL}/${inviteName}:send`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async acceptCustomerInvite(
    {},
    payload: AcceptCustomerInvitePayload
  ): Promise<{
    billingAccountId: string;
    project: string;
  }> {
    try {
      const response = await httpClient.post(`${LocalApiUrl}customer-invite-accept`, {
        customerInviteName: payload.customerInviteName,
        token: payload.token,
        business: payload.business,
        address: payload.address,
        password: payload.password,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getCustomerInviteWithToken({}, token) {
    try {
      const response = await httpClient.post(`${LocalApiUrl}customer-invite`, {
        token,
      });
      return response.data.customerInvite;
    } catch (error) {
      throw error;
    }
  },
};

export default actions;
