export default {
  appListings: [
    {
      id: "1",
      title: "Genesys Open Messaging",
      description: "Easily send and receive SMS using Genesys Cloud and Cloud SMS.",
      imageSrc: "/genesys.svg",
      category: "SMS",
    },
  ],
};
