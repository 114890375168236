import { VoiceApiUrl, BillingApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";
import apiFilter from "@/helpers/apiFilter";
import { CrudService } from "@/api";
import moment from "moment";
import defaultDate from "@/helpers/defaultDate";
const sipDomainsApi = new CrudService(VoiceApiUrl);

export default {
  resetSipDomains({ commit, state }, payload) {
    commit("resetSipDomains", "");
  },
  async getSipDomains({ commit, state, dispatch }, to) {
    const nextPageToken = state.sipDomains.nextPageToken;
    const projectId = to.params.projectId;
    let payload = {};
    if (nextPageToken) {
      payload = {
        nextPageToken,
        projectId,
      };
    } else {
      payload = {
        projectId,
      };
    }

    if (to.query.search && state.sipDomains.data.length > 0) {
      return;
    }

    const parameters = apiFilter(payload, null);
    try {
      const res = await httpClient.get(
        `${VoiceApiUrl}/projects/${projectId}/sipDomains/?page_size=100${parameters}`
      );
      commit("setSipDomains", res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getSipDomain({ commit, state }, to) {
    try {
      const res = await httpClient.get(
        `${VoiceApiUrl}/projects/${to.params.projectId}/sipDomains/${to.params.sipDomainId}`
      );
      commit("setSipDomain", res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async createSipDomain({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    let channelLimitPayload = {};

    if (payload.channelLimit) {
      channelLimitPayload = {
        channelLimit: payload.channelLimit,
      };
    }
    try {
      const res = await httpClient.post(
        `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains?sip_domain_id=${payload.sipDomainId}`,
        {
          displayName: payload.displayName,
          callTerminationMode: "PSTN_TRUNK",
          callTerminationRecordingTrigger: "DISABLED",
          type: payload.sipDomainType,
          ...channelLimitPayload,
        }
      );
      commit("common/setLoading", false, { root: true });
      return res.data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      if (error) {
        if (error.code !== 6) {
          commit("common/setError", error, { root: true });
        } else {
          throw new Error(JSON.stringify(error.code));
        }
      } else {
        throw new Error();
      }
    }
  },
  async updateSipDomain({ dispatch, commit, state, rootState }, payload) {
    try {
      commit("common/setLoading", true, { root: true });

      // Clean up sipDomain payload
      const sipDomainData = { ...payload.sipDomain };
      delete sipDomainData.domainOptional;
      delete sipDomainData.type;
      delete sipDomainData.teamsDirectRoutingStatus;
      delete sipDomainData.fqdn;
      const updateMask = Object.keys(sipDomainData);

      // Prepare recording update mask if present
      let recordingUpdateMask: string[] = [];
      if (payload.recordingPayload) {
        recordingUpdateMask = Object.keys(payload.recordingPayload).map(
          (key) => `callRecording.${key}`
        );
      }

      const aliases = payload.aliases || [];
      const users = payload.users || [];

      // Helper functions for resource operations
      const createAlias = (alias) => dispatch("aliases/createResource", alias, { root: true });
      const updateAlias = (alias) => dispatch("aliases/updateResource", alias, { root: true });
      const createUser = (user) => dispatch("users/createResource", user, { root: true });
      const updateUser = (user) => dispatch("users/updateResource", user, { root: true });

      let aliasesResponse = null;
      let usersResponse = null;

      // Process aliases and users if not Teams Direct Routing
      if (payload.sipDomainType !== "DOMAIN_TYPE_TEAMS_DIRECT_ROUTING") {
        aliasesResponse = await Promise.all(
          aliases.map(async (alias) => {
            if (alias.deleted && alias.name) {
              return dispatch("aliases/deleteAlias", { resourceName: alias.name }, { root: true });
            }
            if (alias.name && !alias.deleted && alias.updated) {
              return updateAlias({
                resourceName: alias.name,
                resource: {
                  displayName: alias.displayName,
                  loadBalancerConfig: alias.loadBalancerConfig,
                },
              });
            }
            if (!alias.deleted) {
              return createAlias({
                alias: alias.aliasId,
                projectId: payload.projectId,
                sipDomainId: payload.sipDomainId,
                resource: alias,
              });
            }
            return null;
          })
        );

        usersResponse = await Promise.all(
          users.map(async (user) => {
            if (user.deleted && user.name) {
              return dispatch("users/deleteResource", { resourceName: user.name }, { root: true });
            }
            if (user.name && !user.deleted && user.updated) {
              return updateUser({
                resourceName: user.name,
                password: user.password,
              });
            }
            if (!user.deleted) {
              return createUser({
                userId: user.userId,
                projectId: payload.projectId,
                sipDomainId: payload.sipDomainId,
                password: user.password,
              });
            }
            return null;
          })
        );
      }

      // Update SIP Domain
      const res = await httpClient.patch(
        `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${
          payload.sipDomainId
        }?update_mask=${updateMask.concat(recordingUpdateMask).join(",")}`,
        {
          ...sipDomainData,
          callRecording: payload.recordingPayload ? { ...payload.recordingPayload } : undefined,
        }
      );

      const previousBundleType = payload.previousBundleType;
      const newBundleType = payload.bundleType;

      // Handle bundle updates
      if (payload.bundlesEnabled && (payload.existingChannels || payload.existingChannels === 0)) {
        const formatBundleType = (type) =>
          payload.userBundlesEnabled && type === "SIP_USER_UNLIMITED"
            ? "sip-user-unlimited"
            : "sip-channel-unlimited";

        // If bundle type is changing, first scale down the previous type to 0
        if (previousBundleType && previousBundleType !== newBundleType) {
          const previousResourceName = `projects/${payload.projectId}/sipDomains/${
            payload.sipDomainId
          }/bundles/${formatBundleType(previousBundleType)}`;

          await dispatch(
            "bundles/scaleBundle",
            {
              resourceName: previousResourceName,
              scale: 0,
            },
            { root: true }
          );
        }

        // Then scale the new bundle type to the desired number
        const bundleType = formatBundleType(newBundleType);
        const resourceName = `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/${bundleType}`;

        await dispatch(
          "bundles/scaleBundle",
          {
            resourceName,
            scale: payload.existingChannels,
          },
          { root: true }
        );

        await dispatch(
          "bundles/getBundle",
          {
            resourceName,
          },
          { root: true }
        );
      }

      // Refresh aliases and users
      await Promise.all([
        dispatch(
          "aliases/getAll",
          {
            projectId: payload.projectId,
            sipDomainId: payload.sipDomainId,
          },
          { root: true }
        ),
        dispatch(
          "users/getAll",
          {
            projectId: payload.projectId,
            sipDomainId: payload.sipDomainId,
          },
          { root: true }
        ),
      ]);

      commit("common/setLoading", false, { root: true });
      commit("common/setSuccess", { data: "SIP Domain updated" }, { root: true });

      return { sipDomain: res.data, aliasesResponse, usersResponse };
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deleteSipDomain({ commit, state }, payload) {
    return sipDomainsApi.deleteResource("SIP Domain", {
      resourceName: `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}`,
    });
  },
  async initializeSipDomainView({ commit, rootState, dispatch }, to) {
    try {
      const userTenantConfig = rootState.user.tenantConfig.userTenantConfig;

      const bundlesEnabled = userTenantConfig?.billing?.enableBundles;
      const userBundlesEnabled = userTenantConfig?.billing?.userBundles;

      const payload = {
        projectId: to.params.projectId,
        sipDomainId: to.params.sipDomainId,
      };

      dispatch("aliases/resetState", to, { root: true });
      const sipDomain = await dispatch("sipDomains/getSipDomain", to, { root: true });

      let sipDomainBundle = null;
      let sipDomainUserBundle = null;

      // Fetch the appropriate bundle based on userBundlesEnabled
      if (bundlesEnabled && !userBundlesEnabled) {
        sipDomainBundle = await httpClient.get(
          `${BillingApiUrl}/projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/sip-channel-unlimited`
        );
      } else if (bundlesEnabled && userBundlesEnabled) {
        sipDomainBundle = await httpClient.get(
          `${BillingApiUrl}/projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/sip-channel-unlimited`
        );

        sipDomainUserBundle = await httpClient.get(
          `${BillingApiUrl}/projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/sip-user-unlimited`
        );
      }

      const userBundleQuantity = sipDomainUserBundle?.data?.activeQuantity || 0;
      const domainBundleQuantity = sipDomainBundle?.data?.activeQuantity || 0;

      const activeBundleData =
        userBundleQuantity > domainBundleQuantity
          ? sipDomainUserBundle?.data
          : sipDomainBundle?.data;

      if (activeBundleData) {
        commit("bundles/setBundle", activeBundleData, { root: true });
      }

      const aliases = await dispatch("aliases/getAll", payload, { root: true });
      const users = await dispatch("users/getAll", payload, { root: true });

      return {
        sipDomainBundle,
        sipDomain,
        aliases,
        users,
      };
    } catch (err) {
      throw err;
    }
  },
  async verifyTeamsDirectRouting({ commit, state }, payload) {
    try {
      const res = await httpClient.post(
        `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${payload.sipDomainId}:verifyTeamsDirectRouting?txt_record_value=${payload.textRecordValue}`
      );
      commit(
        "common/setSuccess",
        {
          data: "SIP Domain verified",
        },
        { root: true }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getUsage({ commit, state, rootState }, payload) {
    if (rootState.common.abortController) {
      rootState.common.abortController.abort();
      commit("common/setAbortController", null, { root: true });
    }
    if (!payload.date) {
      return;
    }
    const filtersVal = payload.date ? payload.date : "month";
    const dateObject = {
      date: !Array.isArray(filtersVal) ? defaultDate[filtersVal] : filtersVal,
    };

    const url = `${VoiceApiUrl}/projects/${payload.projectId}/calls:generateOverviewReport`;
    try {
      const controller = new AbortController();
      commit("common/setAbortController", controller, { root: true });
      const res = await httpClient.post(
        url,
        {
          startDate: {
            year: moment(new Date(dateObject.date[0]).toISOString()).format("YYYY"),
            month: moment(new Date(dateObject.date[0]).toISOString()).format("MM"),
            day: moment(new Date(dateObject.date[0]).toISOString()).format("DD"),
          },
          endDate: {
            year: moment(new Date(dateObject.date[1]).toISOString()).format("YYYY"),
            month: moment(new Date(dateObject.date[1]).toISOString()).format("MM"),
            day: moment(new Date(dateObject.date[1]).toISOString()).format("DD"),
          },
        },
        {
          signal: controller.signal,
          timeout: 60000,
        }
      );
      commit("common/setAbortController", null, { root: true });
      commit("sipDomains/setOverview", res.data, { root: true });
      return res.data;
    } catch (error) {
      if (error.message !== "canceled") {
        commit("common/setAbortController", null, { root: true });
        commit("common/setError", error, { root: true });
      }
      throw error;
    }
  },
};
