import { render, staticRenderFns } from "./EmptyState.vue?vue&type=template&id=5518ee48&scoped=true"
import script from "./EmptyState.vue?vue&type=script&setup=true&lang=ts"
export * from "./EmptyState.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./EmptyState.vue?vue&type=style&index=0&id=5518ee48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5518ee48",
  null
  
)

export default component.exports